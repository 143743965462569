import { Typography } from "@mui/material";
import { getUserDetails } from "api/user";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import Chat from "pages/Chat/Chat";
import IChat from "pages/Chat/IChat";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { chatAction } from "store/chat-slice";
import { userDetailAction } from "store/user-details-slice";
import { userAction } from "store/user-slice";
import AppAlert from "ui/AppAlert";
import AppModal from "ui/AppModal";
import Favico from "favico.js";
import { chatVariablesAction } from "store/chat-variables-slice";
import { VIEWS } from "constants/ChatConstants";
import { appAction } from "store/app-slice";
import AppConstants from "constants/AppConstants";
const userCurrentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

let favico = undefined;

const ChatMainWrapper = () => {
  const dispatch = useDispatch();

  const totalUnreadCount = useSelector((state) => state.chat.unreadCount);
  const favicon = useSelector((state) => state.chatVariables.favicon);
  const iChat = useSelector((state) => state.chatVariables.iChat);
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  const OS = navigate.appVersion;
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(null);

  const stompClient = useSelector((state) => state.app.stompClient);
  const preventRefresh = useSelector((state) => state.app.preventRefresh);

  const {
    sendRequest: getUserDetailsFn,
    data: userDetails,
    status: userFetchStatus,
    error: userFetchErr,
  } = useHttp(getUserDetails, true);
  const getMobileOperatingSystem = () => {
    var userAgent = navigate.userAgent || navigate.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "web";
  };
  const checkNotificationPermissions = () => {
    const time = setTimeout(() => {
      if (Notification.permission !== "granted") {
        setShowAlert(true);
      }
    }, [10000]);
  };

  useEffect(() => {
    getUserDetailsFn(token);
  }, []);

  useEffect(() => {
    favicon?.badge(totalUnreadCount);
  }, [totalUnreadCount]);

  useEffect(() => {
    // Initialize the Favico instance
    favico = new Favico({
      animation: "slide",
      position: "bottom",
      fontFamily: "FontAwesome",
    });

    // Save the Favico instance to the ref to access it later
    dispatch(chatVariablesAction.setFavico(favico));

    // Remember to clean up the Favico instance on component unmount
    return () => {
      if (favicon) favicon?.reset();
      dispatch(chatVariablesAction.destroyFavico());
    };
  }, []);

  useEffect(() => {
    if (userFetchStatus === "completed") {
      if (userDetails) {
        //If timezone is null
        let newDetails = userDetails;
        if (!userDetails.userSettings?.timeZone) {
          newDetails = {
            ...newDetails,
            userSettings: {
              ...newDetails.userSettings,
              timezone: userCurrentTimezone,
            },
          };
        }
        dispatch(userDetailAction.setUserDetails(newDetails));
        dispatch(userAction.setCredit({ credit: userDetails?.totalCredit }));
        if (userDetails.phones[0]) {
          const lastActive = userDetails?.userSettings?.cache?.lastActivePhone;
          // check if userSettings have cached data, if so show the last active phone else first one....
          if (userDetails?.userSettings?.cache && lastActive) {
            //if id -100000, then MERGED VIEW to display
            console.log({ cache: lastActive, phones: userDetails?.phones });
            if (lastActive.phoneId === -100000) {
              const imaginaryNumber = {
                phone: "ALL",
                phoneId: -100000,
              };
              dispatch(chatAction.setCurrentActivePhone(imaginaryNumber));
              dispatch(chatAction.setCurrentActiveView(VIEWS.MERGED_VIEW));
              dispatch(appAction.setMergeViewActive(true));
            } else {
              const dataExists = userDetails?.phones?.find(
                (itm) => itm.phoneId === lastActive?.phoneId
              );
              console.log({ dataExists });
              if (!dataExists || dataExists?.phone !== lastActive?.phone) {
                dispatch(
                  chatAction.setCurrentActivePhone(userDetails?.phones[0])
                );
              } else {
                dispatch(chatAction.setCurrentActivePhone(lastActive));
              }
            }
          } else {
            dispatch(chatAction.setCurrentActivePhone(userDetails?.phones[0]));
          }
        }
        // Set company tags::
        dispatch(appAction.setCompanyTags(userDetails?.company?.tags));
      }
    }
  }, [userFetchStatus, userDetails]);

  useEffect(() => {
    checkNotificationPermissions();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (preventRefresh?.eventInProgress) {
        // Perform actions when page is being refreshed
        // For example, save data or show a confirmation message
        const confirmationMessage =
          "Import is in progress. Are you sure you want to leave?";
        event.returnValue = confirmationMessage; // Some browsers require this
        return confirmationMessage;
      }
    };

    // Add event listener for beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window?.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [preventRefresh]);
  const iosModal = (
    <MDBox display="flex" flexDirection="column" sx={{ padding: "8px" }}>
      <Typography sx={{ marginTop: "8px" }}>
        To get notifications share and Add to homescreen
      </Typography>
      <Typography sx={{ marginTop: "6px", fontSize: "12px" }}>
        {
          "You can always customize or turn off your notifications in your Preferences settings."
        }
      </Typography>
    </MDBox>
  );

  return (
    <>
      {showAlert && (
        <AppAlert
          onAllowed={() => {
            if (Notification.permission !== "denied") {
              Notification.requestPermission().then((permission) => {
                checkNotificationPermissions();
              });
              setShowModal(
                <MDBox
                  display="flex"
                  flexDirection="column"
                  sx={{ padding: "8px" }}
                >
                  <img
                    style={{ height: "300px", objectFit: "contain" }}
                    src={AppConstants.BROWSER_NOTIFICATION_PERMISSION}
                  />
                  <Typography sx={{ marginTop: "8px" }}>
                    Click on allow as shown in above picture, to enable
                    notifications.
                  </Typography>
                  <Typography sx={{ marginTop: "6px", fontSize: "12px" }}>
                    {
                      "You can always customize or turn off your notifications in your Preferences settings."
                    }
                  </Typography>
                </MDBox>
              );
            } else {
              setShowModal(
                <MDBox
                  display="flex"
                  flexDirection="column"
                  sx={{ padding: "8px" }}
                >
                  <img
                    style={{ height: "300px", objectFit: "contain" }}
                    src={AppConstants.BROWSER_NOTIFICATION_PERMISSION}
                  />
                  <Typography sx={{ marginTop: "8px" }}>
                    Please open your browser preferences or click the lock near
                    the address bar as shown in above picture to change your
                    notification preferences to allow.
                  </Typography>
                </MDBox>
              );
            }
          }}
        />
      )}
      {userDetails &&
        (iChat ? (
          <IChat showAlert={showAlert} />
        ) : (
          <Chat showAlert={showAlert} stompClient={stompClient} />
        ))}
      {showModal && getMobileOperatingSystem() !== "Android" && (
        <AppModal
          customStyle={{
            maxWidth: "600px",
            width: "90%",
          }}
          onModalClose={() => {
            setShowAlert(false);
            setShowModal(null);
            checkNotificationPermissions();
          }}
          heading={"Allow Notifications"}
        >
          {getMobileOperatingSystem() == "web" ? showModal : <></>}
          {getMobileOperatingSystem() == "iOS" ? iosModal : <></>}
        </AppModal>
      )}
    </>
  );
};
export default memo(ChatMainWrapper);
