import { createGroup } from "api/group";
import useHttp from "hooks/use-http";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import MDButton from "lib/components/MDButton";
import AppConstants from "constants/AppConstants";
import { TeleGroupServiceAPI } from "services/Chat/TeleGroupServiceAPI";
import { APP_COLOR } from "constants/Color";

const CreateBroadcast = ({ onGroupCreated, currentPhone }) => {
  const token = useSelector((state) => state.user.token);
  const categories = useSelector((state) => state.app.dlc_categories);
  const [currentCategory, setCurrentCategory] = useState(undefined);
  const [minimumAge, setMinimumAge] = useState(undefined);
  const [name, setName] = useState();
  const {
    sendRequest: createGroupFn,
    status,
    data,
    error,
  } = useHttp(createGroup, false);

  const onCreatedGroupHandler = useCallback(() => {
    if (!currentCategory) {
      toast.error("Group category is required.");
      return;
    }
    if (!name || name?.trim().length === 0) {
      toast.error("Group name is required.");
      return;
    }
    const payload = {
      phoneId: currentPhone?.phoneId,
      name,
    };
    TeleGroupServiceAPI.broadcastAvailable({ payload, token })
      .then((res) => {
        if (!res.data) {
          const val = {
            phone_id: currentPhone?.phoneId,
            token: token,
            group_name: name,
            category: {
              ...currentCategory,
            },
            min_age:
              currentCategory?.keyword === "controlled"
                ? minimumAge
                : undefined,
          };
          if (name?.length > 0) createGroupFn(val);
        } else {
          toast.error("Group name already exists.");
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  }, [name, currentCategory, token, currentPhone, createGroupFn, minimumAge]);

  const onEnterPressToCreateGroup = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      onCreatedGroupHandler();
    }
  };

  useEffect(() => {
    if (status === "completed") {
      if (data) {
        onGroupCreated(data);
      } else {
        onGroupCreated();
      }
    }
  }, [status, data]);

  return (
    <MDBox
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      mt={1}
    >
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ maxWidth: "415px", minWidth: "415px" }}
      >
        <MDInput
          onChange={(e) => {
            setName(e.target.value);
          }}
          label={"Group name"}
          fullWidth
          type="text"
        />
      </MDBox>
      <MDBox
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: "12px", maxWidth: "415px", width: "100%" }}
      >
        <FormControl fullWidth variant="outlined">
          <InputLabel id="dlc-category">Choose Category</InputLabel>
          <Select
            labelId="dlc-category"
            fullWidth
            id="dlc-category"
            sx={{
              height: "42px",
              width: "100%",
              "& .MuiSvgIcon-root": {
                color: `${APP_COLOR.APP_SECONDARY_COLOR}`,
                display: "inline-block",
                fontSize:'22px!important'
              }
            }}
            label="Choose Category"
            onChange={(e) => {
              setCurrentCategory(e.target.value);
            }}
          >
            {categories?.map((item) => (
              <MenuItem key={item.id} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <Typography
            color={"info"}
            margin={"8px"}
            sx={{ width: "100%" }}
            fontSize={"12px"}
          >
            {AppConstants.DLC_COMPLIANCE_MESSAGE}
          </Typography>
        </FormControl>
      </MDBox>
      {currentCategory && currentCategory.keyword === "controlled" && (
        <MDBox
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          sx={{ marginTop: "8px", minWidth: "415px", maxWidth: "415px" }}
        >
          <TextField
            onChange={(e) => setMinimumAge(e.target.value)}
            type="number"
            variant="outlined"
            label="Age"
            fullWidth
          />
          <Typography
            sx={{ width: "100%" }}
            color={"info"}
            margin={"8px"}
            fontSize={"12px"}
          >
            Please enter the minimum age for the users allowed to join this
            group.
          </Typography>
        </MDBox>
      )}
      <MDBox
        style={{ marginTop: "12px" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <MDButton
          onClick={onCreatedGroupHandler}
          size={"medium"}
          color="info"
          variant="outlined"
        >
          Create
        </MDButton>
      </MDBox>
    </MDBox>
  );
};
export default CreateBroadcast;
