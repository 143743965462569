import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VIEWS } from "constants/ChatConstants";
import { useState } from "react";
import { useLongPress } from "use-long-press";
import { chatVariablesAction } from "store/chat-variables-slice";
import { CALENDAR_STRING } from "constants/ChatConstants";
import { UserChatBlock } from "pages/Chat/ChatStyles";
import { FormatPhoneNumber } from "util/UtilityFunctions";
import Moment from "react-moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import parse from "html-react-parser";
import {
  Done,
  Edit,
  LocalOfferOutlined,
  NotificationsOff,
} from "@mui/icons-material";
import MDTypography from "lib/components/MDTypography";
import style from "../LeftChatPanel.module.css";
import { chatAction } from "store/chat-slice";
import { Avatar, Badge, Icon, Tooltip } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { useEffect } from "react";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";
import useSend from "hooks/use-send";
import { v4 as uuidv4 } from "uuid";
import { amountOfCreditToBeUsed } from "util/ChatUtil";
import RecipientMenu from "./RecipientMenu";
import EditContact from "./EditContact";
import CreateNRecipientTag from "../NChat/CreateNRecipientTag";
import AppModal from "ui/AppModal";

const LeftChatPanelRecipient = ({
  selected,
  recipient,
  setAssignRecipient,
}) => {
  const dispatch = useDispatch();

  const { send, sendAttachement } = useSend();

  const [openRecipientMenu, setOpenRecipientMenu] = useState(undefined);
  const [openEditContactModal, setOpenEditContactModal] = useState(false);
  const [createTag, setCreateTag] = useState(undefined);

  //Flag when merge view is on:::
  const isMergeView = useSelector((state) => state.app.mergeViewActive);
  const draftsMessage = useSelector(
    (state) => state.draftsMessage?.draftsMessage
  );

  const token = useSelector((state) => state.user.token);
  const timezone = useSelector(
    (state) => state.userDetails?.details?.userSettings?.timezone
  );
  const currentActiveView = useSelector(
    (state) => state.chat.current_active_view
  );
  const currentActivePhone = useSelector(
    (state) => state.chat.current_active_phone
  );
  const isCheckboxSelected = useSelector(
    (state) => state.chatVariables.isCheckboxSelected
  );
  const searchActive = useSelector(
    (state) => state.chatVariables.recipientSearchState
  );
  const checkedMap = useSelector((state) => state.chatVariables.activeCheckbox);
  const currentUser = useSelector((state) => state.userDetails.details);
  const currentMessageType = useSelector(
    (state) => state.chat.current_message_view
  );
  const view = useSelector((state) => state.chat.current_active_view);
  const [isPressMoving, setIsPressMoving] = useState(false);

  const [checkedState, setCheckedState] = useState({});
  const [isHovered, setHovered] = useState(false);

  const callback = useCallback((event) => {
    if (!openRecipientMenu && !isPressMoving) {
      if (event.target.className.includes("MuiBackdrop-root")) {
        setOpenRecipientMenu(null);
      } else {
        setOpenRecipientMenu(event.target);
      }
    }
    if (openRecipientMenu) {
      setOpenRecipientMenu(null);
    }
  }, []);
  const OnLongPress = useLongPress(callback, {
    onStart: (event) => {
      setIsPressMoving(false);
    },
    onMove: (event) => {
      setIsPressMoving(true);
    },
    onFinish: (event) => {
      setIsPressMoving(false);
    },
    filterEvents: (event) => true, // All events can potentially trigger long press (same as 'undefined')
    threshold: 500, // In milliseconds
    captureEvent: true, // Event won't get cleared after React finish processing it
    cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
    cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
    detect: "pointer", // Default option
  });

  const onEventPerform = useCallback(
    (action, data) => {
      if (action === "POPOVER_CLOSED") {
        setOpenRecipientMenu(undefined);
        setHovered(false);
      }
      if (action === "OPEN_TAG") {
        setOpenRecipientMenu(undefined);
        setCreateTag(true);
      }
    },
    [setOpenRecipientMenu, setHovered]
  );

  const checkboxon = (id) => {
    onCheckedEvent(id);
    dispatch(chatVariablesAction.setIsCheckboxSelected(true));
    dispatch(chatVariablesAction.setRightNavChecked(true));
  };

  const handleRecipientClick = useCallback((recipient) => {
    if (searchActive.search) {
      const searchState = {
        search: false,
        pattern: "",
      };
      dispatch(chatVariablesAction.recipientSearch(searchState));
      if (isMergeView) {
        dispatch(
          chatAction.handleSearchRecipientInMergeView({
            phoneId: currentActivePhone.phoneId,
            recipient,
          })
        );
      } else {
        dispatch(
          chatAction.handleSearchRecipient({
            phoneId: currentActivePhone.phoneId,
            recipient,
          })
        );
      }
    } else {
      dispatch(chatAction.setCurrentActiveRecipient(recipient));
    }
  }, []);

  const onCheckedEvent = useCallback(
    (key) => {
      const newCheckedState = {};
      for (const item in checkedState) {
        if (parseInt(item) === key) {
          newCheckedState[item] = {
            ...checkedState[item],
            selected: !checkedState[item].selected,
          };
        } else {
          newCheckedState[item] = {
            ...checkedState[item],
            selected: checkedState[item].selected,
          };
        }
      }
      setCheckedState(newCheckedState);
      dispatch(chatVariablesAction.setActiveCheckbox(newCheckedState));
    },
    [checkedState]
  );

  const isCheck = useCallback(
    (key) => {
      // console.log({checkedState,key})
      const val = checkedState[key]?.selected;
      return val;
    },
    [checkedState]
  );

  useEffect(() => {
    setCheckedState(checkedMap);
  }, [checkedMap]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    if (view !== VIEWS.GROUP_VIEW) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      console.log("files", files);
      sendHandler({
        messageState: { attachement: files[0], message: "" },
        currentActiveRecipient: { id: recipient?.id },
        currentActivePhone: { phoneId: recipient?.appPhone?.phoneId },
        type: currentMessageType === "ALL" ? "SMS" : currentMessageType,
      });
    }
  };

  const sendHandler = (params) => {
    const payload = buildMessageObj(params);
    if (params.messageState.attachement) {
      const formData = new FormData();
      formData.append("media", params.messageState.attachement);
      formData.append("message", JSON.stringify(payload));
      sendAttachement({ formData, token, msgPayload: payload });
      payload.messageAttchement = URL.createObjectURL(
        params.messageState.attachement
      );
      dispatch(
        chatAction.addConversation({
          message: payload,
          recipientId: payload.sendTo.id,
        })
      );
    }
  };

  const buildMessageObj = useCallback((params) => {
    const msgPayload = {
      tempId: uuidv4(),
      sendTo: {
        id: params?.currentActiveRecipient?.id,
      },
      sendByPhone: {
        phoneId: params?.currentActivePhone?.phoneId,
      },
      sendByUser: {
        userId: currentUser.userId,
        fullName: currentUser.fullName,
        profilePic: currentUser.profilePic,
        signature: currentUser?.userSettings?.teleSignature,
      },
      mediumUsed: "APP",
      tags: [],
      creditUsed: amountOfCreditToBeUsed(params.messageState.message),
      message: params.messageState.message,
      inbound: false,
      wMessageType: "text",
      status: "sending",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      type: params.type,
      messageLogType: "NORMAL",
    };
    return msgPayload;
  }, []);

  useEffect(() => {
    if (!Boolean(openRecipientMenu)) {
      setHovered(false);
    }
  }, [openRecipientMenu]);

  return (
    <MDBox display="flex" flexDirection="column">
      <UserChatBlock
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => {
          setHovered(false);
          // if(!Boolean(openRecipientMenu)){
          // }
        }}
        currentSelected={selected}
        onClick={() => handleRecipientClick(recipient)}
        key={"recipient_" + recipient?.id}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        className="draft"
        {...OnLongPress()}
      >
        {recipient?.unreadCount > 0 && (
          <Badge
            sx={{ position: "absolute", right: 25, top: 15 }}
            color="secondary"
            badgeContent={recipient?.unreadCount}
          />
        )}
        <MDBox
          key={recipient?.phone}
          component="li"
          display="flex"
          alignItems="center"
          py={1}
          mb={1}
          sx={{ overflow: "hidden" }}
        >
          <MDBox
            onClick={(event) => {
              //event.stopPropagation();
              checkboxon(recipient.id);
            }}
            sx={{ position: "relative" }}
          >
            <MDBox
              mr={2}
              className={`account-user-icon ${style["recipient-avtar"]} ${
                isCheck(recipient?.id) ? style["recipient-avtar-selected"] : ""
              }`}
            >
              <Avatar sx={{ bgcolor: "#1268db" }} />
              <MDBox
                className={`${
                  isCheck(recipient?.id)
                    ? style["recipient-avtar-selection-selected"]
                    : ""
                } ${style["recipient-avtar-selection"]}`}
              >
                <Done
                  sx={{
                    height: "100%",
                    width: "100%",
                    color: "white!important",
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "100%",
            }}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
          >
            <MDTypography
              sx={{
                fontWeight: `${recipient?.unreadCount > 0 ? "600" : "400"}`,
                width: "100%",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              variant="button"
            >
              {Boolean(recipient?.displayName) && (
                <>
                  <p>{recipient?.displayName}</p>
                </>
              )}
            </MDTypography>
            <MDBox
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <MDTypography
                sx={{
                  fontWeight: `${recipient?.unreadCount > 0 ? "600" : "400"}`,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
                variant="button"
              >
                {FormatPhoneNumber(recipient?.phone, recipient?.countryCode)}
              </MDTypography>
              {/* <DisplayTagOnHover/> */}
              {Boolean(recipient.tags) && (
                <>
                  {recipient?.tags?.map((tag, idx) => (
                    <Tooltip
                      title={tag?.tag?.name}
                      key={"tag" + idx + tag?.tag?.tagId}
                    >
                      <LocalOfferOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          setCreateTag(true);
                        }}
                        sx={{ marginLeft: "1px", color: tag?.tag?.color }}
                      />
                    </Tooltip>
                  ))}
                </>
              )}
            </MDBox>

            {!draftsMessage[recipient.id]?.message?.length && (
              <MDTypography
                variant="caption"
                color="text"
                sx={{
                  fontWeight: `${recipient?.unreadCount > 0 ? "600" : "400"}`,
                  width: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  marginTop: "4px",
                }}
              >
                {recipient?.latestMessage
                  ? parse(
                      recipient?.latestMessage?.message?.replace(
                        /<[^>]+>/g,
                        " "
                      ) || ""
                    )
                  : "No messages yet...."}
              </MDTypography>
            )}
            {/* Drafts MSG */}
            {(draftsMessage[recipient.id]?.message?.length > 0 ||
              draftsMessage[recipient.id]?.attachement) && (
              <MDTypography
                variant="caption"
                color="text"
                sx={{
                  display: "flex",
                  fontWeight: `${recipient?.unreadCount > 0 ? "600" : "400"}`,
                }}
              >
                <MDTypography
                  variant="caption"
                  color="text"
                  sx={{
                    fontWeight: `${recipient?.unreadCount > 0 ? "600" : "400"}`,
                  }}
                >
                  [Draft]
                  {draftsMessage[recipient.id]?.message}{" "}
                  {draftsMessage[recipient.id]?.attachement && (
                    <Icon>perm_media</Icon>
                  )}{" "}
                  {draftsMessage[recipient.id]?.attachement && "file"}
                </MDTypography>

                <Edit sx={{ color: "red", marginLeft: "10px" }} />
              </MDTypography>
            )}
            {recipient?.currentAssignedTo && (
              <MDTypography
                variant="caption"
                sx={{ color: "red" }}
                display="block"
                gutterBottom
              >
                Currently assigned to {recipient?.currentAssignedTo?.fullName}
              </MDTypography>
            )}
            {recipient?.currentAssignedGroup && (
              <MDTypography
                variant="caption"
                sx={{ color: "red" }}
                display="block"
                gutterBottom
              >
                Currently assigned to {recipient?.currentAssignedGroup?.name}
              </MDTypography>
            )}
            {recipient?.contactBlocked && (
              <MDTypography height={"auto"} fontSize={"12px"} color={"error"}>
                {"Contact is blocked."}
              </MDTypography>
            )}
            {isMergeView && (
              <>
                <MDTypography
                  variant="caption"
                  sx={{ color: "black", fontWeight: "600", marginTop: "8px" }}
                  display="block"
                  gutterBottom
                >
                  {"Phone: "}
                  {recipient?.appPhone?.phone}
                </MDTypography>
              </>
            )}
          </MDBox>
        </MDBox>
        <MDBox ml="auto" minWidth="62px!important">
          {recipient?.muted && (
            <NotificationsOff sx={{ height: "18px", width: "18px" }} />
          )}
          <MDTypography fontSize={"12px"}>
            <Moment tz={timezone} calendar={CALENDAR_STRING}>
              {recipient?.latestMessage
                ? getDateConvertedFromUTC(recipient?.latestMessage?.createdAt)
                : getDateConvertedFromUTC(recipient?.updatedAt)}
            </Moment>
          </MDTypography>
          <MDBox
            sx={{ position: "absolute", right: 0, top: 4 }}
            key={recipient.id}
          >
            {!isCheckboxSelected && isHovered && (
              <MoreVertIcon
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setOpenRecipientMenu(e.currentTarget);
                }}
                key={"recipient_menu" + recipient.id}
              />
            )}
            {openRecipientMenu && (
              <RecipientMenu
                key={"menu_" + recipient.id}
                onEventPerform={onEventPerform}
                targetEle={openRecipientMenu}
                setAssignRecipient={setAssignRecipient}
                recipient={recipient}
                setOpenEditContactModal={setOpenEditContactModal}
              />
            )}
          </MDBox>
        </MDBox>
      </UserChatBlock>
      {createTag && (
        <>
          <AppModal
            key={"create_modal" + createTag?.id}
            customStyle={{
              width: "auto",
              minWidth: "400px",
            }}
            closeOnClick={false}
            onModalClose={() => setCreateTag(undefined)}
            heading={`Tags`}
          >
            <CreateNRecipientTag
              key={"create_tag" + recipient?.id}
              recipient={recipient}
              style={{ marginLeft: "4px" }}
            />
          </AppModal>
        </>
      )}
      {openEditContactModal && (
        <AppModal
          onModalClose={() => {
            setOpenEditContactModal(false);
          }}
          heading={"Edit contact"}
        >
          <EditContact
            recipient={recipient}
            onEdited={() => {
              // setAction(undefined);
              // handleClose();
              setOpenEditContactModal(false);
            }}
          />
        </AppModal>
      )}
    </MDBox>
  );
};
export default memo(LeftChatPanelRecipient);
