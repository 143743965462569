import { Paper, Typography } from "@mui/material";
import MDBox from "lib/components/MDBox";
import BackButton from "ui/BackButton";
import CarrierForm from "./CarrierForm";
import MDBadge from "lib/components/MDBadge";
import { saveCarrier } from "services/CarrierAPI";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useHttp from "hooks/use-http";
import AppConstants from "constants/AppConstants";
import { INBOUND_CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";

const AddCarrier = ({ onBack, onCreated }) => {
  const token = useSelector((state) => state.user.token);

  const {
    sendRequest: saveCarrierFn,
    data,
    status,
    error,
  } = useHttp(saveCarrier, false);

  const [carrierData, setCarrierData] = useState({
    carrier_name: "",
    request_type: "POST",
    addendumRequired: false,
    url: "",
    limit: 10,
    messageLength: 160,
    params: [],
    queries: [],
    headers: [],
    auth: {},
    requestBody: {
      body: "",
      type: "JSON",
    },
    response_body: "",
    message: "",
  });

  // editHook flag to decide whether to sync webhook with Carrier Name or not based on Add Carrier or Edit Carrier
  const [inbound, setInbound] = useState({ editHook: true });

  // Helper function to check if a value is empty
  const isEmpty = (value) => {
    return value == null || (typeof value === "string" && value.trim() === "");
  };

  const onSubmit = (req) => {
    console.log("Carrier Data", carrierData);

    // const outboundRequestBody = carrierData?.requestBody;

    // Validating Response Callback Type In Outbound
    // if (!carrierData?.callback_type) {
    //   toast.error("Please select response type");
    //   return;
    // }

    // if (carrierData?.callback_type === "later" && !carrierData?.later) {
    //   toast.error("Please select later to track for response");
    //   return;
    // }

    // const validateParams = () => {
    //   const selectedParams = new Set();
    //   const keysSet = new Set();
    //   const duplicates = new Set();
    //   const keyValue = inbound?.body;

    //   // console.log("Key Value", keyValue);
    //   keyValue.forEach((item) => {
    //     // Check if param exists and value is not empty
    //     if (!isEmpty(item?.param) && !isEmpty(item?.key)) {
    //       selectedParams.add(item.param);
    //     }

    //     // Track duplicate keys
    //     if (item.key) {
    //       if (keysSet.has(item.key)) {
    //         duplicates.add(item.key);
    //       } else {
    //         keysSet.add(item.key);
    //       }
    //     }
    //   });

    //   // Track the missing params by comparing to required options
    //   const missingParams = INBOUND_CARRIER_REQ_PARAMS_OPT.filter(
    //     (option) => !selectedParams.has(option.key) && option.key !== "media"
    //   );

    //   return { missingParams, duplicates: [...duplicates] };
    // };

    const getAuthObject = () => {
      const authObject = carrierData?.auth;

      console.log("auth Key", authObject);

      if (authObject.type === "BASIC") {
        return {
          type: "BASIC",
          username: authObject?.username,
          password: authObject?.password,
        };
      }

      if (authObject.type === "BEARER") {
        return {
          type: "BEARER",
          token: authObject.token,
        };
      }

      return {
        type: "NONE",
      };
    };

    // Perform validation
    if (isEmpty(carrierData.carrier_name)) {
      toast.error("Please fill Carrier Name");
      return;
    }

    // if (isEmpty(carrierData?.response_body)) {
    //   toast.error("Response body Not Found in Outbound Config");
    //   return;
    // }

    // Perform validation before making the request
    // const { missingParams, duplicates } = validateParams();

    // console.log("Values", missingParams, duplicates, inbound);

    // if (missingParams.length > 0) {
    //   const missingKeys = missingParams.map((param) => param.value).join(", ");
    //   toast.error(
    //     `Please define values in Inbound Section for the following params: ${missingKeys}`
    //   );
    //   return;
    // }

    // if (duplicates.length > 0) {
    //   const duplicateKeys = duplicates.join(", ");
    //   toast.error(
    //     `Duplicate keys found in Inbound Section: ${duplicateKeys}. Keys must be unique.`
    //   );
    //   return;
    // }

    // Preparing the Request Body for Save Carrier API
    console.log("Outbound Request Body", getAuthObject());
    const bodyArray = [];
    const body = JSON.parse(carrierData?.requestBody?.body);
    if (body) {
      Object.keys(body).forEach((key) => {
        const bodyItem = {};

        bodyItem["key"] = key;
        bodyItem["type"] = body[key]["type"];
        bodyItem["needsPhone"] = body[key]["needsPhone"] || false;

        const value = body[key]["value"];
        if (bodyItem["type"] === "2") {
          bodyItem["condition"] = value.condition;
          bodyItem["property"] = value.key;
          bodyItem["values"] = {
            true: value.trueValue,
            false: value.falseValue,
          };
        } else {
          bodyItem["value"] = value;
        }

        bodyArray.push(bodyItem);
      });
    }

    const headersArray = [];
    const headers = carrierData?.headers;
    if (headers) {
      headers.forEach((header) => {
        const bodyItem = {};

        bodyItem["key"] = header?.key;
        bodyItem["type"] = header?.type;
        bodyItem["needsPhone"] = header?.needsPhone || false;

        const value = header?.value;
        if (bodyItem["type"] === "2") {
          bodyItem["condition"] = value.condition;
          bodyItem["property"] = value.key;
          bodyItem["values"] = {
            true: value.trueValue,
            false: value.falseValue,
          };
        } else {
          bodyItem["value"] = value;
        }

        headersArray.push(bodyItem);
      });
    }

    const paramsArray = [];
    const params = carrierData?.params;
    if (params) {
      params.forEach((param) => {
        const bodyItem = {};

        bodyItem["key"] = param?.key;
        bodyItem["type"] = param?.type;
        bodyItem["needsPhone"] = param?.needsPhone || false;

        const value = param?.value;
        if (bodyItem["type"] === "2") {
          bodyItem["condition"] = value.condition;
          bodyItem["property"] = value.key;
          bodyItem["values"] = {
            true: value.trueValue,
            false: value.falseValue,
          };
        } else {
          bodyItem["value"] = value;
        }

        paramsArray.push(bodyItem);
      });
    }

    const queriesArray = [];
    const queries = carrierData?.queries;
    if (queries) {
      queries.forEach((query) => {
        const bodyItem = {};

        bodyItem["key"] = query?.key;
        bodyItem["type"] = query?.type;
        bodyItem["needsPhone"] = query?.needsPhone || false;

        const value = query?.value;
        if (bodyItem["type"] === "2") {
          bodyItem["condition"] = value.condition;
          bodyItem["property"] = value.key;
          bodyItem["values"] = {
            true: value.trueValue,
            false: value.falseValue,
          };
        } else {
          bodyItem["value"] = value;
        }

        queriesArray.push(bodyItem);
      });
    }

    const requestBody = {
      carrier_name: carrierData?.carrier_name,
      limit: carrierData?.limit,
      addendumRequired: carrierData?.addendumRequired || false,
      outbound: {
        http_method: "POST",
        api_endpoint: carrierData?.url,
        headers: headersArray,
        auth: getAuthObject(),
        params: paramsArray,
        queries: queriesArray,
        messageLength: carrierData?.messageLength,
        body: bodyArray,
        requestBodyType: carrierData?.requestBody?.type,
        // response_body: carrierData?.response_body,
        // callback_type: carrierData?.callback_type,
        // later: carrierData?.later,
        // ...(carrierData?.media_type && { media_type: carrierData?.media_type }),
      },
      // inbound: {
      //   body: inbound?.body.map((item) => ({
      //     key: item?.key,
      //     value: item?.param,
      //   })),
      // },
      // webhook_path: inbound?.hook,
    };

    // console.log("Request Body Final", requestBody);

    saveCarrierFn({ req: requestBody, token });
  };

  useEffect(() => {
    if (status === "completed") {
      if (data) {
        toast.success("Carrier data saved successfully.");
        // onCreated(data);
        // toast.error("Carrier data saved successfully.");
      }
    }
  }, [data, status, error]);

  return (
    <Paper
      sx={{
        padding: "12px",
      }}
    >
      <MDBox>
        <MDBox display="flex" justifyContent="flex-end">
          <BackButton onClick={onBack} text={"back"} />
        </MDBox>
      </MDBox>
      <MDBox
        sx={{
          backgroundColor: "#fbfbfb",
          padding: "12px",
          marginTop: "8px",
        }}
      >
        <Typography>Connect External API </Typography>
        <Typography fontSize={"16px"}>{AppConstants.CARRIER_INFO}</Typography>
      </MDBox>
      <CarrierForm
        onSubmit={onSubmit}
        carrierData={carrierData}
        setCarrierData={setCarrierData}
        inbound={inbound}
        setInbound={setInbound}
      />
    </Paper>
  );
};

export default AddCarrier;
