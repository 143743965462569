import { Download } from "@mui/icons-material";
import { Typography, IconButton } from "@mui/material";
import MDBox from "lib/components/MDBox";
import React, { useState, useEffect } from "react";
import { ConversationAPI } from "services/ConversationAPI";
import { useSelector } from "react-redux";

const TextReader = ({ url }) => {
  const [textContent, setTextContent] = useState("");

  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const fetchTextFile = () => {
      ConversationAPI.fetchMediaUrl({ token, url })
        .then((response) => {
          setTextContent(response?.data);
        })
        .catch((error) => {
          setTextContent("Unable To Preview Text");
        });
    };

    fetchTextFile();
  }, [url, token]);

  const handleDownload = () => {
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("download", "file.txt");
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <MDBox
      sx={{
        position: "relative",
        background: "#ffffff",
        border: "1px solid #e0e0e0",
        borderRadius: "12px",
        padding: "15px",
        maxHeight: "300px",
        minWidth: "250px",
        overflowY: "auto",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        whiteSpace: "pre-wrap",
      }}
    >
      <Typography fontSize={"14px"} lineHeight={1.6} sx={{ color: "#333" }}>
        {textContent || "Loading content..."}
      </Typography>
      {/* <IconButton
        onClick={handleDownload}
        sx={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          padding: "6px", // Smaller size
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        }}
      >
        <Download sx={{ fontSize: "18px", color: "#555" }} />
      </IconButton> */}
    </MDBox>
  );
};

export default TextReader;
