import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "components/IChat/IChat.module.css";
import { chatVariablesAction } from "store/chat-variables-slice";
import { ConversationAPI } from "services/ConversationAPI";
import { chatAction } from "store/chat-slice";
import useDebouncedWatch from "./use-debounce-watch";
import { is } from "immutable";

const TAG = "useScrollToBottom";

const useScrollToBottom = (
  messages,
  firstUnreadMessageId,
  scroll = true,
  targetMessageId = null
) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user);
  const [isNewlyLaunched, setIsNewlyLaunched] = useState(false);
  const scrollRef = useRef(null);
  const [messageRead, setMessageRead] = useState([]);
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const currentActivePhone = useSelector(
    (state) => state.chat.current_active_phone
  );
  const latestMessageFromSocket = useSelector(
    (state) => state.chat.lastReceivedMessage
  );
  // Helper function to mark a message as read
  const markMessageAsRead = (message) => {
    setMessageRead((prev) => [...prev, message]);
  };

  /**
   * Function to handle the scroll event on the chat container
   */
  const handleScroll = () => {
    if (scrollRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef?.current;
      const isScrolledUp =
        Math.ceil(Math.abs(scrollTop)) > Math.ceil(clientHeight);

      dispatch(chatVariablesAction.setScrollChatBottomBtn(isScrolledUp));
      messages?.forEach((message) => {
        const messageElement = scrollRef?.current.querySelector(
          `[data-message-id="${message.id}"]`
        );
        if (messageElement) {
          const messageRect = messageElement.getBoundingClientRect();

          // Check if the message is visible in the container
          const isVisible =
            messageRect.top >= 0 &&
            messageRect.bottom <=
              (window.innerHeight || document.documentElement.clientHeight);

          const shouldMark =
            isVisible && message.inbound && !message.messageRead;
          if (shouldMark) {
            markMessageAsRead(message);
          }
        }
      });
    }
  };

  /**
   * Function to scroll to the bottom of the chat container
   */
  const scrollToBottom = () => {
    console.log("calling scroll to bottom...");
    setTimeout(() => {
      if (scrollRef?.current) {
        const containerElement = scrollRef?.current;
        containerElement.scrollTop = containerElement.scrollHeight;
        containerElement.className = style["smooth-scroll-container"];
        // Scrolled to bottom reduce count to zero
        if (currentActiveRecipient)
          dispatch(
            chatAction.updateUnreadCountToZeroForRecipient({
              recipientId: currentActiveRecipient?.id,
            })
          );
      }
    }, 100);
  };

  /**
   * Function to mark messages as read when they are in the viewport using the api call
   */
  useDebouncedWatch(() => {
    console.log(messageRead);
    if (messageRead && messageRead.length > 0) {
      const ids = messageRead.map((itm) => itm.id);
      const newSet = new Set();
      for (const id of ids) {
        newSet.add(id);
      }
      const payload = {
        ids: [...newSet],
        userId: currentUser.id,
        phoneId: currentActivePhone?.phoneId,
        recipientId: currentActiveRecipient?.id,
        phone: currentActivePhone.phone,
      };
      ConversationAPI.markAsReadConversation({
        payload,
        token: currentUser.token,
      })
        .then((res) => {
          setMessageRead([]);
        })
        .catch((e) => console.log(e));
    }
  }, [messageRead, currentUser, currentActivePhone, currentActiveRecipient]);

  /**
   * Function to mark messages as read when they are in the viewport
   */
  useEffect(() => {
    // Calculate which messages are in the viewport
    let t = undefined;
    if (messages && messages.length > 0) {
      t = setTimeout(() => {
        messages.forEach((message) => {
          const messageElement = scrollRef?.current.querySelector(
            `[data-message-id="${message.id}"]`
          );
          if (messageElement) {
            const { top, bottom } = messageElement.getBoundingClientRect();
            if (top >= 0 && bottom <= window.innerHeight) {
              // Message is fully in the viewport
              if (message.inbound && !message.messageRead) {
                markMessageAsRead(message);
              }
            }
          }
        });
      }, 100);
    }
    return () => clearTimeout(t);
  }, [messages, scrollRef]);

  /**
   * Function to set the isNewlyLaunched state to true when the current active recipient changes
   */
  useEffect(() => {
    setIsNewlyLaunched(true);
    console.log(TAG, "isNewlyLaunched set to true");
  }, [currentActiveRecipient]);

  /**
   * Function to scroll to the last read message when the isNewlyLaunched state is true
   */
  useEffect(() => {
    if (isNewlyLaunched) {
      console.log(
        TAG,
        "inside isNewlyLaunched block",
        firstUnreadMessageId,
        latestMessageFromSocket
      );

      if (
        firstUnreadMessageId &&
        (!latestMessageFromSocket ||
          firstUnreadMessageId !== latestMessageFromSocket.id)
      ) {
        console.log(TAG, "qualifies for scrolling into view");

        // Locate the DOM element for the last read message
        const lastReadMessageElement = document.querySelector(
          `[data-message-id="${firstUnreadMessageId}"]`
        );
        if (lastReadMessageElement) {
          // console.log(TAG, "has lastReadMessageElement");

          // Scroll to the last read message with a smooth animation
          setTimeout(() => {
            console.log(TAG, "triggering scrollIntoView");

            lastReadMessageElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 500); // Ensure DOM rendering before scrolling

          setIsNewlyLaunched(false);
        }
      }
    }
  }, [firstUnreadMessageId, isNewlyLaunched]);

  /**
   * Function to scroll to the last message when the lastMessageScrollRequired state is true
   */
  useEffect(() => {
    if (targetMessageId) {
      console.log("Last Read Message taregt Truggered");
      let targetMessageElement = "";
      setTimeout(() => {
        targetMessageElement = document.querySelector(
          `[data-message-id="${targetMessageId}"]`
        );
        console.log("target scroll", targetMessageElement, targetMessageId);
        if (targetMessageElement && scroll) {
          setTimeout(() => {
            targetMessageElement.scrollIntoView({ behavior: "smooth" });
            // Add highlight class to target message
            targetMessageElement.classList.add(style["message_highlight"]);
            // Remove highlight after some time (optional)
            setTimeout(() => {
              targetMessageElement.classList.remove(style["message_highlight"]);
            }, 3000);
          }, 500);
        }
      }, 500);
    }
  }, [targetMessageId, currentActiveRecipient]);

  /**
   * Function to add scroll event listener to the chat container
   */
  useEffect(() => {
    const el = scrollRef.current;
    if (!el) return;

    el.addEventListener("scroll", handleScroll);
    return () => el.removeEventListener("scroll", handleScroll);
  }, [scrollRef.current, handleScroll]);

  return { scrollRef, scrollToBottom };
};

export default useScrollToBottom;
