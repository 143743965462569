import { List, ListItem, Popover } from "@mui/material";
import { RecipientAPI } from "services/Chat/RecipientAPI";
import { ConversationAPI } from "services/ConversationAPI";
import { toast } from "react-toastify";
import { messageAction } from "store/message-slice";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatAction } from "store/chat-slice";
import AppModal from "ui/AppModal";
import EditContact from "./EditContact";
import Swal from "sweetalert2";
import { loaderAction } from "store/loader-slice";
import { useTheme } from "@mui/material/styles";

const RECIPIENT_ACTIONS = {
  EDIT: "edit",
};
const RecipientMenu = ({
  targetEle,
  recipient,
  setAssignRecipient,
  onEventPerform,
  setOpenEditContactModal,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token);
  const currentUser = useSelector((state) => state.user);
  const currentActivePhone = useSelector(
    (state) => state.chat.current_active_phone
  );

  const [anchorEl, setAnchorEl] = useState(targetEle);
  const [action, setAction] = useState(undefined);

  const handleClose = () => {
    setAnchorEl(null);
    onEventPerform("POPOVER_CLOSED");
  };

  const blockRecipient = useCallback(
    (id) => {
      //call endpoint to, Block the given recipient
      handleClose();
      RecipientAPI.blockRecipient({
        recipientId: id,
        token,
        companyId: currentUser?.company?.company_id,
      })
        .then((res) => {
          toast.success(res.data);
          //dispatch block action
          dispatch(chatAction.blockContact(id));
        })
        .catch((e) => console.log(e));
    },
    [currentUser?.company, token]
  );

  const unblockRecipient = useCallback(
    (id) => {
      // Inblock the recipient
      handleClose();
      RecipientAPI.unblockRecipient({
        recipientId: id,
        token,
        companyId: currentUser?.company?.company_id,
      })
        .then((res) => {
          toast.success("Unblocked successfully.");
          // dispatch to mark as unblocked:
          dispatch(chatAction.unblockRecipients(res.data));
        })
        .catch((e) => console.log(e));
    },
    [currentUser?.company, token]
  );

  const toggleMute = (recipientId, muted) => {
    handleClose();
    RecipientAPI.toggleMute({ token, muted, recipientId });
    dispatch(chatAction.toggleMute({ muted, recipientId }));
  };

  const unassignRecipient = useCallback(
    (recipient) => {
      const payload = {
        recipient_id: recipient.id,
        phone_id: currentActivePhone?.phoneId,
        user_id: currentUser.id,
        group_id: recipient?.currentAssignedGroup
          ? recipient?.currentAssignedGroup.id
          : undefined,
      };

      console.log("Unassigning recipient", payload);
      ConversationAPI.unassignConversation({ payload, token })
        .then((res) => {
          toast.success("Conversation unassigned.");
          handleClose();
          dispatch(
            messageAction.addConversation({
              message: res.data,
              recipientId: recipient.id,
            })
          );
          dispatch(
            chatAction.updateLatestMessage({
              message: res.data,
              recipientId: recipient.id,
            })
          );
          dispatch(
            chatAction.updateAssignTo({
              user: null,
              recipientId: res.data.sendTo.id,
              group: 1,
            })
          );
        })
        .catch((err) => console.log(err));
    },
    [currentActivePhone, currentUser, token]
  );

  const editRecipientHandler = (recipient) => {
    setAction(RECIPIENT_ACTIONS.EDIT);
    setOpenEditContactModal(true);
    handleClose();
  };

  const createTag = () => {};

  const deleteRecipientHandler = useCallback(
    (id) => {
      //TODO:: Delete for other views
      Swal.fire({
        title: "Are you sure want to delete this contact?",
        text: "This action will delete all the data related to this contact.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        customClass: {
          container: {
            zIndex: "10000 !important",
          },
        },
      }).then((result) => {
        if (result.isConfirmed) {
          handleClose();
          dispatch(loaderAction.show());
          RecipientAPI.deleteRecipient({
            recipientId: id,
            token,
            companyId: currentUser?.company?.company_id,
          })
            .then((res) => {
              toast.success("Contact deleted successfully.");
              // dispatch to mark as unblocked:
              dispatch(chatAction.deleteContact(id));
              dispatch(loaderAction.hide());
            })
            .catch((e) => {
              dispatch(loaderAction.hide());
              toast.error(e?.response?.data?.message);
            });
        }
      });
    },
    [currentUser?.company]
  );

  useEffect(() => {
    setAnchorEl(targetEle);
  }, [targetEle]);

  return (
    <>
      <Popover
        onClose={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "white!important",
            display: "flex",
            width: "auto",
            justifyContent: "center",
            border: "solid 1px #ddd",
            padding: "12px",
            boxShadow: "-4px 2px 6px 0px #979797",
            userSelect: "none",
          },
        }}
      >
        <List>
          <ListItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              editRecipientHandler(recipient);
            }}
            sx={{
              fontSize: "14px",
              cursor: "pointer",
              padding: "2px 6px",
              borderRadius: "8px",
              transition: "background-color 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                backgroundColor: "#1976d2",
                color: theme.palette.primary.contrastText,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
              {"Edit contact"}
            </span>
          </ListItem>
          {!recipient?.currentAssignedTo && !recipient?.currentAssignedGroup ? (
            <ListItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
                setAssignRecipient(recipient);
              }}
              sx={{
                fontSize: "14px",
                cursor: "pointer",
                padding: "2px 6px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: theme.palette.primary.contrastText,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
                {"Assign conversation"}
              </span>
            </ListItem>
          ) : (
            <ListItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleClose();
                unassignRecipient(recipient);
              }}
              sx={{
                fontSize: "14px",
                cursor: "pointer",
                padding: "2px 6px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: theme.palette.primary.contrastText,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
                {"Unassign conversation"}
              </span>
            </ListItem>
          )}
          {recipient?.muted ? (
            <ListItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleMute(recipient.id, false);
              }}
              sx={{
                fontSize: "14px",
                cursor: "pointer",
                padding: "2px 6px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: theme.palette.primary.contrastText,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
                {"Unmute"}
              </span>
            </ListItem>
          ) : (
            <ListItem
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleMute(recipient.id, true);
              }}
              sx={{
                fontSize: "14px",
                cursor: "pointer",
                padding: "2px 6px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: theme.palette.primary.contrastText,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
                {"Mute"}
              </span>
            </ListItem>
          )}
          {!recipient?.contactBlocked ? (
            <ListItem
              sx={{
                fontSize: "14px",
                cursor: "pointer",
                padding: "2px 6px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: theme.palette.primary.contrastText,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                blockRecipient(recipient.id);
              }}
            >
              <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
                {"Block"}
              </span>
            </ListItem>
          ) : (
            <ListItem
              sx={{
                fontSize: "14px",
                cursor: "pointer",
                padding: "2px 6px",
                borderRadius: "8px",
                transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  backgroundColor: "#1976d2",
                  color: theme.palette.primary.contrastText,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                unblockRecipient(recipient.id);
              }}
            >
              <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
                {"Unblock"}
              </span>
            </ListItem>
          )}
          <ListItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onEventPerform("OPEN_TAG");
            }}
            sx={{
              fontSize: "14px",
              cursor: "pointer",
              padding: "2px 6px",
              borderRadius: "8px",
              transition: "background-color 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                backgroundColor: "#1976d2",
                color: theme.palette.primary.contrastText,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
              {"Tags"}
            </span>
          </ListItem>
          <ListItem
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              deleteRecipientHandler(recipient.id);
            }}
            sx={{
              fontSize: "14px",
              cursor: "pointer",
              padding: "2px 6px",
              borderRadius: "8px",
              transition: "background-color 0.3s ease, box-shadow 0.3s ease",
              "&:hover": {
                backgroundColor: "#1976d2",
                color: theme.palette.primary.contrastText,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <span style={{ fontSize: "14px !important", fontWeight: "400" }}>
              {"Delete contact"}
            </span>
          </ListItem>
        </List>
      </Popover>
      {/* {action === RECIPIENT_ACTIONS.EDIT && (
        <AppModal onModalClose={() => {}} heading={"Edit contact"}>
          <EditContact
            recipient={recipient}
            onEdited={() => {
              setAction(undefined);
              handleClose();
            }}
          />
        </AppModal>
      )} */}
    </>
  );
};
export default memo(RecipientMenu);
