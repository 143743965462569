import {
  MessageWrapper,
  MessageChat,
  MainChatPanel,
} from "pages/Chat/ChatStyles";
import { useDispatch, useSelector } from "react-redux";
import NoContent from "../NoContent";
import React, {
  useCallback,
  useEffect,
  useState,
  memo,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import MDBox from "lib/components/MDBox";
import Divider from "@mui/material/Divider";
import { CircularProgress, SpeedDial } from "@mui/material";
import SingleRightMessage from "../SingleRightMessage";
import { MESSAGE_EVENTS, VIEWS } from "constants/ChatConstants";
import SingleLeftMessage from "../SingleLeftMessage";
import AssignmentDetails from "../AssignmentDetails";
import UnreadDivider from "../UnreadDivider";
import useScrollToBottom from "hooks/use-scroll-to-bottom";
import useChat from "hooks/use-chat";
import useChatV1 from "hooks/use-chatv1";
import { chatVariablesAction } from "store/chat-variables-slice";
import InfiniteScroll from "react-infinite-scroll-component";
import { chatAction } from "store/chat-slice";
import { messageAction } from "store/message-slice";
import { KeyboardArrowDown } from "@mui/icons-material";

const TAG = "NConversation";

const NConversation = ({ setMessageState }) => {
  // useChat();
  // useChatV1();

  const [showLoader, setShowLoader] = useState(false);
  const { loadConversation } = useChatV1({ setShowLoader });

  const dispatch = useDispatch();
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const view = useSelector((state) => state.chat.current_active_view);
  const scrollChatBottomBtn = useSelector(
    (state) => state.chatVariables.scrollChatBottomBtn
  );
  const conversation = useSelector(
    (state) => state.message.conversation[currentActiveRecipient?.id]
  );
  console.log("Conversations", conversation);
  const messages = conversation?.messages;
  const currentMessageView = useSelector(
    (state) => state.chat.current_message_view
  );
  const timezone = useSelector(
    (state) => state.userDetail?.details?.userSettings?.timeZone
  );
  const activeCheckbox = useSelector(
    (state) => state.chatVariables.activeCheckbox
  );
  const isCheckboxSelected = useSelector(
    (state) => state.chatVariables.isCheckboxSelected
  );
  const targetMessageId = useSelector(
    (state) =>
      state.message.conversation[currentActiveRecipient?.id]?.targetMessageId
  );
  console.log(TAG, ".targetMessageId", targetMessageId);
  const latestMessageFromSocket = useSelector(
    (state) => state.chat.lastReceivedMessage
  );

  const [inlineSearch, setInlineSearch] = useState({
    search: false,
    pattern: "",
    criterion: "NORMAL_SEARCH",
  });
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState(null);
  const [isRecipientLoading, setIsRecipientLoading] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [scrollDirection, setScrollDirection] = useState("prev");
  const [showDivider, setShowDivider] = useState(false);
  const [isAPIProgress, setIsAPIProgress] = useState(false);
  const [isNewlyLaunched, setIsNewlyLaunched] = useState(false);

  const { scrollRef, scrollToBottom } = useScrollToBottom(
    messages,
    firstUnreadMessageId,
    scroll,
    targetMessageId
  );
  const previousScrollTop = useRef(0);
  const previousScrollHeight = useRef(0);

  const loadMore = (direction) => {
    if (isAPIProgress) {
      return;
    }
    if (scroll) {
      setScroll(false);
    }
    setIsAPIProgress(true);
    loadConversation(direction);
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    if (scrollTop < previousScrollTop.current) {
      setScrollDirection("prev");
    } else if (scrollTop >= previousScrollTop.current) {
      setScrollDirection("next");
      if (
        Math.abs(scrollTop) - 500 < 0 &&
        Boolean(conversation?.nextPageCursor)
      ) {
        loadMore("next");
      }
    }

    previousScrollTop.current = scrollTop;
  };

  const onEventPerFormInMessageComponent = useCallback(
    (action, data) => {
      if (action === "CHECKBOX") {
        const recipientId = currentActiveRecipient?.id;
        if (!data.checked) {
          dispatch(
            chatVariablesAction.pushId({ key: recipientId, id: data.id })
          );
        } else {
          const newMap = activeCheckbox[recipientId]?.messageIds.filter(
            (item) => item !== data.id
          );
          dispatch(
            chatVariablesAction.updateIds({ ids: newMap, key: recipientId })
          );
        }
      }
    },
    [currentActiveRecipient, activeCheckbox, dispatch]
  );

  const formatMessages = useMemo(
    () => (message, index) => {
      const isThisUnreadMessage = message.id === firstUnreadMessageId;
      // console.log(
      //   TAG,
      //   "formatMessages",
      //   message.id,
      //   // "isThisUnreadMessage ",
      //   message.id === firstUnreadMessageId,
      //   // " showDivider ",
      //   showDivider
      // );
      const divider =
        isThisUnreadMessage && showDivider ? (
          <UnreadDivider key={`unread_divider_${index}`} />
        ) : null;

      const showReadBy = index === 0 && messages[index]?.inbound;
      if (
        message?.messageEvent === MESSAGE_EVENTS.CONVERSATION_ASSIGNED ||
        message?.messageEvent === MESSAGE_EVENTS.CONVERSATION_UNASSIGNED
      ) {
        return (
          <>
            {divider}
            <AssignmentDetails
              key={`assignment_${index}`}
              message={message}
              index={index}
              timezone={timezone}
            />
          </>
        );
      }

      if (view === VIEWS.SCHEDULED_VIEW) {
        return message?.inbound === false && message?.status === "scheduled" ? (
          <>
            {divider}
            <SingleRightMessage
              key={`scheduled_right_${index}`}
              message={message}
              user={message?.sendByUser}
              setMessageState={setMessageState}
              check={activeCheckbox[currentActiveRecipient?.id]?.selected}
              searchActive={inlineSearch}
              onEventPerform={onEventPerFormInMessageComponent}
              checkboxSelected={isCheckboxSelected}
            />
          </>
        ) : (
          <></>
        );
      }

      return message?.inbound ? (
        <>
          <SingleLeftMessage
            key={`left_msg_${message.id}_${index}`}
            message={message}
            user={message?.sendByUser}
            check={activeCheckbox[currentActiveRecipient?.id]?.selected}
            searchActive={inlineSearch}
            showReadBy={showReadBy}
            checkboxSelected={isCheckboxSelected}
            onEventPerform={onEventPerFormInMessageComponent}
          />
          {divider}
        </>
      ) : (
        <>
          <SingleRightMessage
            key={`right_msg_${index}`}
            message={message}
            user={message?.sendByUser}
            setMessageState={setMessageState}
            check={activeCheckbox[currentActiveRecipient?.id]?.selected}
            searchActive={inlineSearch}
            checkboxSelected={isCheckboxSelected}
            onEventPerform={onEventPerFormInMessageComponent}
          />
          {divider}
        </>
      );
    },
    [
      messages,
      view,
      currentActiveRecipient,
      activeCheckbox,
      setMessageState,
      timezone,
      inlineSearch,
      isCheckboxSelected,
      onEventPerFormInMessageComponent,
      firstUnreadMessageId,
      showDivider,
    ]
  );

  /**
   * Function to set the isNewlyLaunched state to true when the current active recipient changes
   */
  useEffect(() => {
    setIsNewlyLaunched(true);
    setShowDivider(false);
    setFirstUnreadMessageId(null);
    console.log(TAG, "isNewlyLaunched set to true");

    previousScrollTop.current = 0;
    previousScrollHeight.current = 0;
  }, [currentActiveRecipient]);

  /**
   * Function to set the first unread message id when the messages are loaded
   */
  useEffect(() => {
    if (targetMessageId) {
      console.info(
        TAG,
        "targetMessageId is set, returning [as per Kamruddin's comments]"
      );
      return;
    }

    if (!firstUnreadMessageId) {
      if (messages) {
        const firstUnreadMessage = messages
          .filter((m) => !m.messageRead && m.inbound)
          .pop();

        console.log(TAG, "filtering firstUnreadMessage ", firstUnreadMessage);
        if (firstUnreadMessage) setFirstUnreadMessageId(firstUnreadMessage?.id);
      }
    }
  }, [messages]);

  /**
   * Function to show unread divider when the first unread message id is set
   */
  useEffect(() => {
    if (isNewlyLaunched) {
      console.log(
        TAG,
        "inside isNewlyLaunched block",
        firstUnreadMessageId,
        latestMessageFromSocket
      );

      if (
        firstUnreadMessageId &&
        firstUnreadMessageId !== latestMessageFromSocket?.id
      ) {
        console.log(TAG, "qualifies for showing unread divider");

        setShowDivider(true);
        setIsNewlyLaunched(false);
      }
    }
  }, [firstUnreadMessageId, isNewlyLaunched]);

  /**
   * Function to save last carrier type of the conversation
   */
  useEffect(() => {
    const _lastMsgType = messages?.[0]?.type || "SMS";
    dispatch(chatAction.setCurrentMessageType(_lastMsgType));
  }, [messages, dispatch]);

  /**
   * Function to stabilize scrollbar in case messages are added at both ends of the array
   */
  useLayoutEffect(() => {
    if (isNewlyLaunched && !targetMessageId) {
      if (isAPIProgress) {
        setTimeout(() => {
          setIsAPIProgress(false);
        }, 500);
      }
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollDirection === "next") {
      const newTop =
        previousScrollTop.current -
        (scrollHeight - previousScrollHeight.current);

      console.log(TAG, ".useLayoutEffect: new scrolled top ", newTop);
      scrollRef.current.scrollTop = newTop;
    }

    previousScrollHeight.current = scrollHeight;
    if (isAPIProgress) {
      setTimeout(() => {
        setIsAPIProgress(false);
      }, 500);
    }
  }, [messages]);

  useLayoutEffect(() => {
    setShowLoader(true);
  }, [currentActiveRecipient]);

  return (
    <MainChatPanel>
      <MessageWrapper>
        <MessageChat
          ref={scrollRef}
          id="scrollableDiv_normal"
          style={{
            height: 300,
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {showLoader ? (
            <>
              <MDBox
                width="100%"
                height="100vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </MDBox>
            </>
          ) : messages?.length ? (
            <>
              <InfiniteScroll
                onScroll={handleScroll}
                dataLength={messages.length}
                inverse={true}
                next={() => loadMore("prev")}
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
                hasMore={
                  Boolean(conversation?.prevPageCursor) &&
                  messages.filter((itm) => {
                    if (currentMessageView === "SMS") return itm.type === "SMS";
                    if (currentMessageView === "WHATSAPP")
                      return itm.type === "WHATSAPP";
                    return true;
                  }).length > 0
                }
                loader={
                  <MDBox
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    sx={{ overflow: "hidden" }}
                  >
                    <CircularProgress />
                  </MDBox>
                }
                scrollableTarget="scrollableDiv_normal"
              >
                {messages.filter((itm) => {
                  if (currentMessageView === "SMS") return itm.type === "SMS";
                  if (currentMessageView === "WHATSAPP")
                    return itm.type === "WHATSAPP";
                  return true;
                }).length === 0 ? (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                  >
                    <NoContent
                      style={{
                        height: "100%",
                        marginBottom: "calc(100vh - 50%)",
                      }}
                      heading="h5"
                      message="No Messages Available for the selected filter."
                    />
                  </MDBox>
                ) : (
                  messages
                    .filter((itm) => {
                      if (currentMessageView === "SMS")
                        return itm.type === "SMS";
                      if (currentMessageView === "WHATSAPP")
                        return itm.type === "WHATSAPP";
                      return true;
                    })
                    .map((msg, index) => formatMessages(msg, index))
                )}
              </InfiniteScroll>
            </>
          ) : (
            <NoContent
              heading="h5"
              style={{ height: "100%" }}
              message="You have no existing conversations with this number."
            />
          )}
        </MessageChat>
      </MessageWrapper>
      {scrollChatBottomBtn && (
        <SpeedDial
          onClick={scrollToBottom}
          ariaLabel="scroll to bottom"
          sx={{ position: "absolute", bottom: 38, right: 16 }}
          icon={
            <KeyboardArrowDown
              sx={{ height: "24px!important", width: "24px!important" }}
            />
          }
        />
      )}
    </MainChatPanel>
  );
};

export default memo(NConversation);
