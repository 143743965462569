import { InputBase } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { CustomChatEditor } from "pages/Chat/ChatStyles";
import { ChatIcons } from "pages/Chat/ChatStyles";
import { ChatEditorWrapper } from "pages/Chat/ChatStyles";
import { ChatBottomPanel } from "pages/Chat/ChatStyles";
import MessageIconsFunctionality from "./MessageIconsFunctionality";
import SendMessage from "./SendMessage";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatFooter as FooterStyle } from "pages/Chat/ChatStyles";
import CancelIcon from "@mui/icons-material/Cancel";
import Audio from "components/Audio/Audio";
import style from "pages/Chat/Chat.module.css";
import { useCallback } from "react";
import { VIEWS } from "constants/ChatConstants";
import MediaQuery from "react-responsive";
import MiniMessageIconsFunctionality from "./MiniMessageIconsFunctionality";
import { useEffect } from "react";
import { draftsMessageAction } from "store/draft-message-slice";
import { getBlobFromUrl } from "util/MediaUtil";
import RenderComposeMedia from "components/util/ChatCompose/RenderComposeMedia";
import CarrierDropdown from "./CarrierDropdown";

const ChatFooter = ({
  messageState,
  setMessageState,
  sendMessageHandler,
  onReplying = undefined,
  autoFocus = true,
}) => {
  const sendRef = useRef();
  const messageInputRef = useRef();
  const dispatch = useDispatch();
  const [openMessagePopup, setOpenMessagePopUp] = useState(undefined);
  const view = useSelector((state) => state.chat.current_active_view);
  const currentMessageType = useSelector(
    (state) => state.chat.current_message_view
  );
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );

  const currentMsgType = useSelector(
    (state) => state.chat.current_last_msg_type
  );
  const [audioMessage, setAudioMessage] = useState(false);
  const [carrier, setCarrier] = useState(currentMsgType || "SMS");
  const currentUser = useSelector((state) => state.userDetails.details);
  const currentReplying = useSelector((state) => state.chat.current_reply_user);
  const draftsMessage = useSelector(
    (state) => state.draftsMessage?.draftsMessage
  );

  const messageType = ({ type, scheduledAt }) => {
    if (messageState?.message?.trim().length === 0 && !messageState.attachement)
      return;
    sendMessageHandler({ messageState, type, scheduledAt });
    setMessageState({ message: "", attachement: undefined });
    setAudioMessage(false);

    dispatch(
      draftsMessageAction.setDraftsMessage({
        ...draftsMessage,
        [`${currentActiveRecipient?.id}`]: {
          messageState: "",
          attachement: undefined,
        },
      })
    );
  };

  // const onEnterPressToSendMessage = useCallback(
  //   (e) => {
  //     if (e.keyCode === 13 && e.shiftKey === false) {
  //       e.preventDefault();
  //       e.stopPropagation();
  //       if (window.screen.width < 700) {
  //         messageInputRef?.current?.blur();
  //         setTimeout(() => {
  //           if (currentMessageType === "ALL") {
  //             //messageInputRef?.current?.blur()
  //             setOpenMessagePopUp(sendRef.current);
  //           } else {
  //             messageType({ type: currentMessageType });
  //           }
  //         }, 500);
  //       } else {
  //         if (currentMessageType === "ALL") {
  //           //messageInputRef?.current?.blur()
  //           setOpenMessagePopUp(sendRef.current);
  //         } else {
  //           messageType({ type: currentMessageType });
  //         }
  //       }
  //     }
  //   },
  //   [currentMessageType, messageState, messageType]
  // );

  const onEnterPressToSendMessage = useCallback(
    (e) => {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        // Now we always send via the "carrier" state
        messageType({ type: carrier });
      }
    },
    [carrier, messageState]
  );
  useEffect(() => {
    const draftMsgFn = async () => {
      if (currentActiveRecipient) {
        setMessageState({
          message: draftsMessage[currentActiveRecipient?.id]?.message
            ? draftsMessage[currentActiveRecipient?.id]?.message
            : "",
          attachement: draftsMessage[currentActiveRecipient?.id]?.attachement
            ? await getBlobFromUrl(
                draftsMessage[currentActiveRecipient?.id]?.attachement
              )
            : undefined,
        });
        if (window.innerWidth > 768 && !currentActiveRecipient?.phoneBlocked) {
          messageInputRef?.current?.focus();
        }
      }
    };
    draftMsgFn();
  }, [currentActiveRecipient]);

  useEffect(() => {
    setCarrier(currentMsgType);
  }, [currentMsgType]);
  return (
    <ChatBottomPanel
      className={
        (view !== VIEWS.GROUP_VIEW &&
          currentReplying?.replying &&
          currentReplying.user.userId !== currentUser.userId &&
          currentActiveRecipient.id === currentReplying.recipientId) ||
        currentActiveRecipient?.phoneBlocked
          ? style["chat_blocked"]
          : ""
      }
    >
      <>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <CarrierDropdown
            currentCarrier={carrier}
            onCarrierChange={setCarrier}
          />
        </MDBox>
        {messageState.attachement && !audioMessage && (
          <MDBox
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
          >
            <MDBox
              display="flex"
              flexDirection="column"
              maxWidth="200px"
              className={style["msg-attachment"]}
            >
              <CancelIcon
                onClick={() => {
                  setMessageState((prev) => {
                    return {
                      ...prev,
                      attachement: undefined,
                    };
                  });
                  dispatch(
                    draftsMessageAction.setDraftsMessage({
                      ...draftsMessage,
                      [`${currentActiveRecipient?.id}`]: {
                        ...draftsMessage[`${currentActiveRecipient?.id}`],
                        attachement: undefined,
                      },
                    })
                  );
                }}
                className={style["cancel_icon"]}
              />
              <RenderComposeMedia file={messageState.attachement} />
            </MDBox>
          </MDBox>
        )}
        <FooterStyle>
          {!audioMessage ? (
            <ChatEditorWrapper>
              {view !== VIEWS.GROUP_VIEW &&
                currentReplying?.replying &&
                currentReplying?.user.userId !== currentUser.userId &&
                currentActiveRecipient.id === currentReplying.recipientId && (
                  <span className={style["typing_indicator"]}>
                    {`@${currentReplying?.user.fullName} is replying...`}{" "}
                  </span>
                )}
              <CustomChatEditor>
                <InputBase
                  multiline
                  onKeyDown={onEnterPressToSendMessage}
                  onPaste={(e) => {
                    const clipboardItems = e.clipboardData.items;
                    const items = [].slice
                      .call(clipboardItems)
                      .filter(function (item) {
                        return /^image\//.test(item.type);
                      });
                    if (items.length === 0) {
                      return;
                    }

                    const item = items[0];
                    const blob = item.getAsFile();
                    setMessageState((prev) => {
                      return {
                        ...prev,
                        attachement: blob,
                      };
                    });
                    e.preventDefault();
                  }}
                  onChange={(e) => {
                    if (onReplying) {
                      onReplying(e, messageInputRef);
                    }
                    if (view !== VIEWS.GROUP_VIEW) {
                      setMessageState((prev) => {
                        return {
                          ...prev,
                          message: e.target.value,
                        };
                      });
                      if (
                        draftsMessage[`${currentActiveRecipient?.id}`]?.message
                          ?.length > 0 ||
                        draftsMessage[`${currentActiveRecipient?.id}`]
                          ?.attachement
                      ) {
                        dispatch(
                          draftsMessageAction.setDraftsMessage({
                            ...draftsMessage,
                            [`${currentActiveRecipient?.id}`]: {
                              message: "",
                              attachement: undefined,
                            },
                          })
                        );
                      }
                    }
                  }}
                  inputRef={messageInputRef}
                  style={{
                    fontSize: "16px",
                    color: "#555",
                    lineHeight: "18px",
                    width: "100%",
                  }}
                  autoFocus={autoFocus}
                  variant="standard"
                  value={messageState.message}
                  placeholder=""
                />
              </CustomChatEditor>
              <ChatIcons>
                <MediaQuery maxWidth={1224}>
                  <MiniMessageIconsFunctionality
                    setMessageState={setMessageState}
                    messageState={messageState}
                    handleTemplateMessage={(data) => {}}
                    onAttachmentAdded={(data) => {
                      setMessageState((prev) => {
                        return {
                          ...prev,
                          attachement: data,
                        };
                      });
                    }}
                    onEmojiSelect={(emoji) => {
                      const m =
                        messageState.message.substr(
                          0,
                          messageInputRef.current.selectionStart
                        ) +
                        emoji +
                        messageState.message.substr(
                          messageInputRef.current.selectionEnd
                        );
                      setMessageState((prev) => {
                        return {
                          ...prev,
                          message: m,
                        };
                      });
                    }}
                    setAudioMessage={setAudioMessage}
                  />
                </MediaQuery>
                <MediaQuery minWidth={1224}>
                  <MessageIconsFunctionality
                    setMessageState={setMessageState}
                    messageState={messageState}
                    handleTemplateMessage={() => {}}
                    onAttachmentAdded={(data) => {
                      setMessageState((prev) => {
                        return {
                          ...prev,
                          attachement: data,
                        };
                      });
                    }}
                    onEmojiSelect={(emoji) => {
                      const m =
                        messageState.message.substr(
                          0,
                          messageInputRef.current.selectionStart
                        ) +
                        emoji +
                        messageState.message.substr(
                          messageInputRef.current.selectionEnd
                        );
                      console.log("m", m);
                      setMessageState((prev) => {
                        return {
                          ...prev,
                          message: m,
                        };
                      });
                    }}
                    setAudioMessage={setAudioMessage}
                  />
                </MediaQuery>
              </ChatIcons>
            </ChatEditorWrapper>
          ) : (
            <Audio
              setAudioMessage={setAudioMessage}
              setMessageState={setMessageState}
            />
          )}
          <SendMessage
            onCancel={() => setOpenMessagePopUp(undefined)}
            setOpenMessagePopUp={setOpenMessagePopUp}
            onSend={(obj) => {
              messageType(obj);
              setTimeout(() => {
                messageInputRef?.current?.focus();
              }, 1000);
            }}
            ref={sendRef}
            openMessagePopup={openMessagePopup}
            view={view}
            setMessageState={setMessageState}
            messageState={messageState}
            selectedCarrier={carrier}
          />
        </FooterStyle>
      </>
    </ChatBottomPanel>
  );
};
export default ChatFooter;
