import { FormControl, InputLabel, MenuItem } from "@mui/material";
import useHttp from "hooks/use-http";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import MDTypography from "lib/components/MDTypography";
import { useEffect, useRef, useState } from "react";
import {createUser} from 'api/user'
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getUserCompany } from "api/company";
import CustomSelect from "ui/Select";
import { sortByString } from "util/UtilityFunctions";
import { useCallback } from "react";

const CreateUserForm = ({onCreate}) => {

  const {sendRequest : createNewUserFn, status, data , error} = useHttp(createUser, false);
  
  const {sendRequest : getUserComanyFn, status : userCompanyStatus, data:userCompanyList , error:userCompanyError} 
    = useHttp(getUserCompany, false);

  const currentUserRole = useSelector(state => state.user.role);
  const currentUserId = useSelector(state => state.user.id);
  const token = useSelector(state => state.user.token);

  const [nameValidation, setNameValidation] = useState('');
  const [emailValidation, setEmailValidation] = useState('');
  const [passwordValidation, setPasswordValidation] = useState('');
  const [companyValidation,setCompanyValidation] = useState('');
  const [contactValidation, setContactValidation] = useState('');
  const nameRef = useRef();
  const emailRef = useRef();
  const contactRef = useRef();
  const passwordRef = useRef();
  const [roleRef,setRoleRef] = useState('');
  const [company,setCompany] = useState('');

  const handleRoleChange = (e) => {
    setRoleRef(e.target.value);
  }
  const handleCompanyChange = (e) => {
    setCompany(e.target.value);
  }
  const onFormSubmit = useCallback((event) => {
    event.preventDefault();
    const fullName = nameRef.current.value;
    const email = emailRef.current.value;
    const contact = contactRef.current.value;
    const password = passwordRef.current.value;
    if(fullName === ''){
      setNameValidation('Name can not be empty.');
    }
    if(email === ''){
      setEmailValidation('Please enter a valid email.');
    }    
    if(password === ''){
      setPasswordValidation('Please enter a valid password.');
    }
    if(company === ''){
      setCompanyValidation('Please select the company, user belongs to.');
    }
    if(contact.trim().length > 0 && !(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(contact)){
      setContactValidation("Please enter a valid number.");
      return;
    }
    if(fullName !== '' && email !== '' && password !== '' && company !== ''){
      const newUser = {fullName,email,password};
      
      let user = {};
      const findCompany = userCompanyList.find(itm  => itm.company_id === company);
      let userCompany = {
        company_id : company,
        company_name : findCompany.company_name
      }
      if(currentUserRole === 'SUPER_ADMIN'){
        if(roleRef.trim().length === 0){
          return;
        }
        user = {
          ...newUser,
          roles :[{
            "name" : roleRef
          }]
        }
      }
      else{
        user = {
          ...newUser,
          roles :[{
            "name" : "USER"
          }]
        }
      }
      if(contact && contact.trim().length > 0){
        user['contactNumber'] = contact;
      }
      user['company'] = userCompany;
      createNewUserFn(user);    
    }
    else return;
  },[userCompanyList,company]);

  useEffect(() => {
    getUserComanyFn({token,userId:currentUserId});
  },[]);

  useEffect(() => {
    if(userCompanyStatus === 'completed'){

    }
  },[userCompanyStatus]);

  useEffect(() => {
    if(status === 'completed'){
      if(error == null){
        toast.success("New user created successfully.");
        onCreate(data);
      }
      else{
        toast.error(error);
      }
    } 
  }, [status,error])
  return (
    <MDBox width="100%" display="flex" flexDirection="column">
      <MDBox pt={2} pb={3} px={3} onSubmit={onFormSubmit}>
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput type="text" label="Name" variant="standard" fullWidth inputRef={nameRef} />
          </MDBox>
          <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {nameValidation}
          </MDTypography>
                   
          <MDBox mb={2}>
            <MDInput type="email" label="Email" variant="standard" fullWidth inputRef={emailRef} />
          </MDBox>
          <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {emailValidation}
          </MDTypography>  
          <MDBox mb={2}>
            <MDBox display="flex" flexDirection="column">
              <MDInput type="number" label="Contact Number" variant="standard" fullWidth inputRef={contactRef} />
              <MDTypography variant={"caption"} fontSize={"14px"} mt={1} color={"info"}>{"This number will be used for 2 factor authentication."}</MDTypography>
            </MDBox>
          </MDBox>       
          <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {contactValidation}
          </MDTypography>                      
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Password"
              variant="standard"
              fullWidth
              inputRef={passwordRef}
            />
          </MDBox>
          <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
            {passwordValidation}
          </MDTypography>   
                
          {currentUserRole === 'SUPER_ADMIN' && 
          <>
            <FormControl>
              <InputLabel id="demo-select-small">Role</InputLabel>
              <CustomSelect
                labelId="demo-select-small"
                style={{ width: "300px", height: "40px" }}
                id="demo-select-small"
                value={roleRef}
                label="Role"
                onChange={handleRoleChange}
              >
                <MenuItem key={'admin'} value={'ADMIN'}>ADMIN</MenuItem>
                <MenuItem key={'user'} value={'USER'}>USER</MenuItem>
              </CustomSelect>
            </FormControl>
            </>
            } 
          <MDBox mt={2} display="flex" flexDirection="column">
            <FormControl>
              <InputLabel id="select-company">Select company user belongs to:</InputLabel>
                <CustomSelect
                  labelId="select-company"
                  style={{ width: "300px", height: "40px" }}
                  id="select-company"
                  value={company}
                  label="Select company user belongs to:"
                  onChange={handleCompanyChange}
                >
                {userCompanyList?.sort((a,b) => sortByString(a?.company_name,b?.company_name))?.map(item => <MenuItem key={item.company_id} value={item.company_id}>{item.company_name}</MenuItem>)}
                </CustomSelect>
            </FormControl>
              <MDTypography variant="overline" fontWeight="medium" color="error" mt={1}>
              {companyValidation}
          </MDTypography>
          </MDBox>  
          <MDBox mt={4} mb={1}>
            <MDButton type="submit" variant="gradient" color="info" fullWidth>
              Create
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};
export default CreateUserForm;
