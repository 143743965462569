import React, { useEffect, useState } from "react";
import {
  ChatLeftPanel,
  ChatMainWrapper,
  ChatRightPanel,
} from "pages/Chat/ChatStyles";
import "./Chat.module.css";
import { useDispatch, useSelector } from "react-redux";
import { chatAction } from "store/chat-slice";
import ChatNavBar from "components/Chat/Nav/ChatNavBar";
import RightChatPanelNavBar from "components/Chat/Nav/RightChatPanelNavBar";
import useHttp from "hooks/use-http";
import NoPhoneUI from "components/util/NoPhoneUI";
import { markWAMessageRead } from "api/chat";
import ChatSideBar from "components/CommonUI/ChatSideBar";
import RecipientList from "components/Chat/RecipientList";
import { VIEWS } from "constants/ChatConstants";
import BroadcastFooterWrapper from "components/Chat/Broadcast/BroadcastFooterWrapper";
import NFooterWrapper from "components/Chat/NChat/NFooterWrapper";
import useRecipient from "hooks/use-recipient";
import usePhoneStatus from "hooks/use-phone-status";
import { useCallback } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import MDBox from "lib/components/MDBox";
import ConversationWrapper from "components/Chat/ConversationWrapper";
import MediaQuery from "react-responsive";
import { ACTIONS } from "constants/ChatConstants";
import useEvent from "hooks/use-event";
import { parseWSData } from "util/UtilityFunctions";
import { WEBSOCKET_EVENT_TYPE } from "constants/ChatConstants";
import { MESSAGE_EVENTS } from "constants/ChatConstants";
import { draftsMessageAction } from "store/draft-message-slice";
import { getAsDataUrl } from "util/MediaUtil";
import { messageAction } from "store/message-slice";
import { SearchOuterWrapper } from "./ChatStyles";
import RecipientSearch from "components/Chat/RecipientSearch";
import AppModal from "ui/AppModal";
import { appAction } from "store/app-slice";
import { toast } from "react-toastify";

const Chat = ({ showAlert, stompClient }) => {
  const dispatch = useDispatch();
  const [messageState, setMessageState] = useState({
    message: "",
    attachement: undefined,
  });
  const [searchParent, enable] = useAutoAnimate();

  const drag = React.useRef(null);
  const dynamicContent = useSelector((state) => state.app.chatDynamicComponent);

  const userDetails = useSelector((state) => state.userDetails?.details);
  const token = useSelector((state) => state.user.token);
  const sideBar = useSelector((state) => state.ichatVariables.rightSideBar);
  const [currentUser, setCurrentUser] = useState(userDetails);
  const [draftUserInfo, setDraftsUserInfo] = useState({ m: "", id: null });
  const [dragging, setDragging] = useState(false);
  //migration to Redux managed state...
  const isCheckboxSelected = useSelector(
    (state) => state.chatVariables.isCheckboxSelected
  );
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const view = useSelector((state) => state.chat.current_active_view);
  const currentActivePhone = useSelector(
    (state) => state.chat.current_active_phone
  );
  //end of block
  const currentActiveGroup = useSelector(
    (state) => state.group.current_active_group
  );
  const draftsMessage = useSelector(
    (state) => state.draftsMessage?.draftsMessage
  );
  const { onEventReceive } = useEvent();

  const onMessageReceiveEvent = useCallback(
    (res) => {
      try {
        console.info("Running onMessageReceiveEvent fn...");
        // Acknowledge the message after processing
        const payload = parseWSData(res.body);
        console.log(payload);
        stompClient.ack(res.headers["message-id"], res.headers.subscription);
        if (payload.ws_type === WEBSOCKET_EVENT_TYPE.SMS_MESSAGE) {
          onEventReceive(WEBSOCKET_EVENT_TYPE.SMS_MESSAGE, payload.message);
        } else if (payload.ws_type === MESSAGE_EVENTS.CONVERSATION_ASSIGNED) {
          console.log(payload);
          onEventReceive(MESSAGE_EVENTS.CONVERSATION_ASSIGNED, payload);
        } else if (payload.ws_type === MESSAGE_EVENTS.CONVERSATION_UNASSIGNED) {
          console.log(payload);
          onEventReceive(MESSAGE_EVENTS.CONVERSATION_UNASSIGNED, payload);
        } else if (payload.ws_type === WEBSOCKET_EVENT_TYPE.SMS_READ) {
          try {
            dispatch(
              chatAction.updateUnreadCount({
                recipientId: payload.recipientId,
                count: -payload.ids.length,
              })
            );
            dispatch(messageAction.updateMessageAsRead(payload));
          } catch {}
        } else if (payload.ws_type === WEBSOCKET_EVENT_TYPE.WA_MESSAGE_STATUS) {
          try {
          } catch {}
        } else if (payload.ws_type === WEBSOCKET_EVENT_TYPE.MESSAGE_STATUS) {
          // update message status here,
          dispatch(messageAction.updateMessageStatus(payload.message));
        } else if (payload.ws_type === WEBSOCKET_EVENT_TYPE.SMS_UNREAD) {
          // Change status locally and update unread count...
          dispatch(
            chatAction.updateUnreadCount({
              recipientId: payload.recipientId,
              count: payload.count,
            })
          );
          dispatch(
            chatAction.setTotalUnreadCount({ count: payload.totalCount })
          );
          // Mark last message readby to null
          dispatch(
            messageAction.markLastMessageReadByNull(payload.recipientId)
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    [onEventReceive, dispatch, stompClient]
  );

  const onCurrentReplyEvent = useCallback(
    (res) => {
      console.log(res);
      try {
        const payload = JSON.parse(res.body);
        if (payload.user.userId !== currentUser.userId) {
          dispatch(chatAction.setReplying({ payload }));
        }
      } catch (ex) {
        console.log(ex);
      }
    },
    [currentUser, dispatch]
  );

  // Custom hooks
  const { reloadRecipients, addRecipient } = useRecipient();
  usePhoneStatus(onMessageReceiveEvent, onCurrentReplyEvent);

  const { sendRequest: markWAMessagesAsReadFn } = useHttp(
    markWAMessageRead,
    false,
    false
  );

  /// EVENT

  const onEventPerformInChatNavBar = (action, data) => {
    if (action === "BULK_UPLOAD") {
      if (data.data === "INDIVIDUAL") {
        dispatch(chatAction.setCurrentActiveView(VIEWS.NORMAL_VIEW));
        dispatch(chatAction.resetChats());
        dispatch(messageAction.resetChats());
        reloadRecipients();
      } else if (data.data === "GROUP") {
        dispatch(chatAction.setCurrentActiveView("GROUP_VIEW"));
        dispatch(chatAction.setCurrentActiveRecipient(undefined));
      }
    } else if (action === ACTIONS.RELOAD_RECIPIENT) {
      reloadRecipients();
      //Set chat states to initials as reloading the entire recipients...
      dispatch(chatAction.resetChats());
      dispatch(messageAction.resetChats());
    }
  };

  const buildStatusPayload = useCallback(() => {
    return {
      user: {
        userId: currentUser?.userId,
        fullName: currentUser?.fullName,
        profilePic: currentUser?.profilePic,
      },
      recipientId: currentActiveRecipient?.id,
      phoneId: currentActivePhone?.phoneId,
      token: token,
      replying: true,
      key: currentActivePhone?.phoneId + "_" + currentActiveRecipient?.id,
    };
  }, [currentActivePhone, currentActiveRecipient, currentUser, token]);
  //////// CUSTOM EFFECT ////////

  useEffect(() => {
    if (messageState.message.length > 0) {
      if (stompClient && currentActivePhone && currentActiveRecipient) {
        const payload = JSON.stringify(buildStatusPayload());
        try {
          stompClient?.send(
            `/app/${currentActivePhone.phoneId}.${currentActiveRecipient?.id}`,
            {},
            payload
          );
        } catch (e) {}
      }
    } else {
      if (stompClient && currentActivePhone && currentActiveRecipient) {
        const payload = buildStatusPayload();
        payload.replying = false;
        try {
          stompClient?.send(
            `/app/${currentActivePhone.phoneId}.${currentActiveRecipient?.id}`,
            {},
            JSON.stringify(payload)
          );
        } catch (e) {}
      }
    }
  }, [messageState, currentActivePhone, currentActiveRecipient, stompClient]);

  useEffect(() => {
    if (userDetails) {
      setCurrentUser(userDetails);
    }
  }, [userDetails]);

  // useEffect(() => {
  //   if(currentActivePhone && currentActiveRecipient){
  //     loadMoreConversation(0);
  //     setCurrentPage(0);
  //     if(currentMessageType === 'WHATSAPP'){
  //       markWAMessagesAsReadFn({token,phone_id: currentActivePhone?.phoneId,recipient_id: currentActiveRecipient?.id,})
  //     }
  //   }
  // },[currentMessageType])

  useEffect(() => {
    const dUserInfoFn = async () => {
      if (
        (view === VIEWS.GROUP_VIEW && currentActiveGroup) ||
        currentActiveRecipient
      ) {
        let chatId =
          view === VIEWS.GROUP_VIEW &&
          (currentActiveGroup?.id || currentActiveRecipient?.id);
        if (
          draftUserInfo?.id !== null &&
          (messageState?.message !==
            draftsMessage[draftUserInfo?.id]?.message ||
            messageState?.attachement !==
              draftsMessage[draftUserInfo?.id]?.attachement) &&
          draftUserInfo?.id !== chatId
        ) {
          dispatch(
            draftsMessageAction.setDraftsMessage({
              ...draftsMessage,
              [`${draftUserInfo?.id}`]: {
                ...messageState,
                attachement:
                  messageState?.attachement &&
                  (await getAsDataUrl(messageState.attachement)),
              },
            })
          );
        }
      }
    };
    dUserInfoFn();
  }, [
    draftUserInfo,
    currentActiveGroup,
    currentActiveRecipient,
    dispatch,
    draftsMessage,
    view,
  ]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const supportsFileSystemAccessAPI =
      "getAsFileSystemHandle" in DataTransferItem.prototype;
    const files = event.dataTransfer.files;
    const fileHandlesPromises = [...event.dataTransfer.items]
      .filter((item) => item.kind === "file")
      .map((item) =>
        supportsFileSystemAccessAPI
          ? item.getAsFileSystemHandle()
          : item.webkitGetAsEntry()
      );
    const handle = await fileHandlesPromises[0];
    if (handle?.kind === "directory" || handle?.isDirectory) {
      setDragging(false);
      toast.error("UzipFolder not supported.");
      return;
    }
    if (view !== VIEWS.GROUP_VIEW && currentActiveRecipient && files[0]) {
      setDragging(false);
      setMessageState((prev) => {
        return {
          ...prev,
          attachement: files[0],
        };
      });
    }
    setDragging(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (
      e.target !== drag.current &&
      view !== VIEWS.GROUP_VIEW &&
      currentActiveRecipient
    ) {
      setDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      e.target === drag.current &&
      view !== VIEWS.GROUP_VIEW &&
      currentActiveRecipient
    ) {
      setDragging(false);
    }
  };

  if (!userDetails?.phones[0]) return <NoPhoneUI userDetails={userDetails} />;
  return (
    <>
      <ChatMainWrapper isShowAlert={showAlert} style={{ overflowX: "hidden" }}>
        {/**Render for larger screens Screen ie. above mobile */}
        <MediaQuery minWidth={768}>
          <ChatLeftPanel
            isCustomCheckbox={isCheckboxSelected}
            onMouseDown={async (e) => {
              if (
                !document.querySelector(".draft") ||
                (document.querySelector(".draft") !== null &&
                  !document?.querySelector(".draft")?.contains(e.target))
              ) {
                if (
                  (view === VIEWS.GROUP_VIEW && currentActiveGroup) ||
                  currentActiveRecipient
                ) {
                  let chatId =
                    (view === VIEWS.GROUP_VIEW && currentActiveGroup?.id) ||
                    currentActiveRecipient?.id;
                  if (
                    draftUserInfo?.id !== null &&
                    (messageState?.message !== draftsMessage[chatId]?.message ||
                      messageState?.attachement !==
                        draftsMessage[chatId]?.attachement) &&
                    draftUserInfo.id !== chatId
                  ) {
                    dispatch(
                      draftsMessageAction.setDraftsMessage({
                        ...draftsMessage,
                        [`${chatId}`]: {
                          ...messageState,
                          attachement:
                            messageState?.attachement &&
                            (await getAsDataUrl(messageState.attachement)),
                        },
                      })
                    );
                  }
                }
              }
            }}
            onClick={(e) => {
              setDraftsUserInfo({
                m: "",
                id: null,
              });
              if (
                (view === VIEWS.GROUP_VIEW && currentActiveGroup) ||
                currentActiveRecipient
              ) {
                let selectedUser = document.querySelector(
                  ".hWmHfE .eIYSuk .draft div div div div.idQygM li div span.css-18ym6au-MuiTypography-root"
                );
                setDraftsUserInfo({
                  m: selectedUser?.innerText,
                  id:
                    view === VIEWS.GROUP_VIEW &&
                    (currentActiveGroup?.id || currentActiveRecipient?.id),
                });
              }
            }}
          >
            {userDetails && (
              <ChatNavBar
                onEventPerform={onEventPerformInChatNavBar}
                reloadRecipients={reloadRecipients}
              />
            )}
            {currentActivePhone && <ChatSideBar view={view} />}
            <RecipientList
              addRecipient={addRecipient}
              reloadRecipients={reloadRecipients}
            />
          </ChatLeftPanel>
          <MDBox
            ref={searchParent}
            display="flex"
            flexDirection="row"
            width="100%"
          >
            <ChatRightPanel
              style={{
                width: `${sideBar.open ? "60%" : "100%"}`,
                borderRight: "1px solid rgb(221, 221, 221)",
                minWidth: "300px",
              }}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
            >
              {dragging && (
                <MDBox
                  sx={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    bottom: "0",
                    right: "0",
                    height: "100%",
                    width: "100%",
                    zIndex: "9999",
                    background: "rgba(0, 0, 0, 0.7)",
                    border: "2px dashed #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "25px",
                    fontWeight: "500",
                    color: "#fff",
                    textAlign: "center",
                  }}
                  ref={drag}
                >
                  Drop file
                </MDBox>
              )}
              {currentUser && <RightChatPanelNavBar />}

              <ConversationWrapper setMessageState={setMessageState} />
              {view === VIEWS.GROUP_VIEW
                ? currentActiveGroup && (
                    <BroadcastFooterWrapper
                      key={"Gfooter"}
                      messageState={messageState}
                      setMessageState={setMessageState}
                    />
                  )
                : currentActiveRecipient && (
                    <NFooterWrapper
                      key={"Nfooter"}
                      messageState={messageState}
                      setMessageState={setMessageState}
                    />
                  )}
            </ChatRightPanel>
            {sideBar?.open && (
              <MDBox
                minWidth="40%"
                maxWidth="40%"
                height="100%"
                sx={{ backgroundColor: "rgb(255, 255, 255)" }}
              >
                {sideBar?.content}
              </MDBox>
            )}
          </MDBox>
        </MediaQuery>

        {/**For smaller devicess....*/}
        <MediaQuery maxWidth={768}>
          {currentUser && <RightChatPanelNavBar />}
          <ChatLeftPanel isCustomCheckbox={isCheckboxSelected}>
            {currentActivePhone &&
              !currentActiveRecipient &&
              !currentActiveGroup && <ChatSideBar view={view} />}
            {currentActiveRecipient || currentActiveGroup ? (
              <>
                <ConversationWrapper setMessageState={setMessageState} />
                {view === VIEWS.GROUP_VIEW
                  ? currentActiveGroup && (
                      <BroadcastFooterWrapper
                        key={"Gfooter"}
                        messageState={messageState}
                        setMessageState={setMessageState}
                      />
                    )
                  : currentActiveRecipient && (
                      <NFooterWrapper
                        key={"Nfooter"}
                        messageState={messageState}
                        setMessageState={setMessageState}
                      />
                    )}
              </>
            ) : (
              <>
                <MDBox
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  sx={{ marginTop: "12px", marginBottom: "8px" }}
                >
                  <SearchOuterWrapper style={{ width: "unset" }}>
                    <RecipientSearch />
                  </SearchOuterWrapper>
                </MDBox>
                <RecipientList
                  addRecipient={addRecipient}
                  reloadRecipients={reloadRecipients}
                />
              </>
            )}
          </ChatLeftPanel>
        </MediaQuery>
      </ChatMainWrapper>
      {/***CHAT dynamic contents can be placed here */}
      {Boolean(dynamicContent) && (
        <AppModal
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "scroll",
            border: "unset",
          }}
          rootStyle={{
            "&.MuiModal-root": {
              width: "60%",
              left: "40%",
            },
          }}
          heading={" "}
          showCloseButton={false}
          onModalClose={() =>
            dispatch(appAction.setChatDynamicComponent(undefined))
          }
        >
          {dynamicContent}
        </AppModal>
      )}
    </>
  );
};
export default React.memo(Chat);
