import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";
import axios from "axios";

const CARRIER_APPEND = "/phones/carriers";

export const saveCarrier = async ({ req, token }) => {
  try {
    const response = await axios.post(`${BASE_URL}${CARRIER_APPEND}`, req, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Could not call the endpoint."
    );
  }
};
export const editCarrier = async ({ req, token }) => {
  try {
    const response = await axios.put(`${BASE_URL}${CARRIER_APPEND}`, req, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Could not call the endpoint."
    );
  }
};
export const getAllCarrier = async (token) => {
  const response = await fetch(`${BASE_URL}${CARRIER_APPEND}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not call the endpoint.");
  }
  return data;
};

export const getCarrierResponse = async (carrierData, token) => {
  // Helper Fuction to get Auth Object
  const getAuthObject = (authObject) => {
    console.log("auth Key", authObject);

    if (authObject.key === "basic_auth") {
      return {
        type: "BASIC",
        username: authObject?.value?.userName,
        password: authObject?.value?.pass,
      };
    }

    if (authObject.key === "bearer_token") {
      return {
        type: "BEARER",
        token: authObject.value,
      };
    }

    return {
      type: "NONE",
    };
  };
  try {
    const method = carrierData.http_method || "POST";
    let url = carrierData.send_url;

    if (!/^https?:\/\//i.test(url)) {
      url = `https://${url}`;
    }

    const encodedUrl = encodeURI(url);

    const keyValue = carrierData.request_body;
    const authObject = getAuthObject(keyValue.auth);

    const outboundRequestBody = {
      headers: keyValue?.headers || [],
      params: keyValue?.params || [],
      body: keyValue?.body || [],
    };

    // Construct the outbound object
    const outboundAPIObject = {
      http_method: method,
      api_endpoint: encodedUrl,
      headers: outboundRequestBody?.headers.map((item) => ({
        key: item?.key,
        param: item?.param,
        value: item?.value, // Assuming value instead of param for headers
      })),
      auth: authObject,
      params: outboundRequestBody?.params.map((item) => ({
        key: item?.key,
        param: item?.param,
        value: item?.value, // Assuming value instead of param for params
      })),
      body: outboundRequestBody?.body.map((item) => ({
        key: item?.key,
        param: item?.param,
        value: item?.value, // Assuming value instead of param for body
      })),
    };

    console.log("Outbound Body API", outboundAPIObject);

    // API call with the outbound object
    const response = await axios.post(
      `${BASE_URL}${CARRIER_APPEND}/prefetch`,
      JSON.stringify(outboundAPIObject),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Return the API response
    return response;
    // console.log("Response", response);

    // Dummy Response to check and debug.
    // const response = {
    //   data: {
    //     message_id: 113065,
    //     text: "1",
    //     media: [
    //       {
    //         id: 4670,
    //         uuid: null,
    //         src: "https://example.com/x5rff.png",
    //       },
    //     ],
    //     inbound: false,
    //     scheduled_at: null,
    //     status: "sending",
    //     created_at: "2024-10-09T16:04:07.420765126",
    //     updated_at: null,
    //     sent_by_phone: "8483122221",
    //     sent_to: "8482165040",
    //     is_broadcast: false,
    //     broadcast_name: null,
    //   },
    //   status: 200,
    //   statusText: "",
    //   headers: {
    //     "cache-control": "no-cache, no-store, max-age=0, must-revalidate",
    //     "content-type": "application/json",
    //     expires: "0",
    //     pragma: "no-cache",
    //   },
    //   config: {
    //     transitional: {
    //       silentJSONParsing: true,
    //       forcedJSONParsing: true,
    //       clarifyTimeoutError: false,
    //     },
    //     adapter: ["xhr", "http", "fetch"],
    //     transformRequest: [null],
    //     transformResponse: [null],
    //     timeout: 0,
    //     xsrfCookieName: "XSRF-TOKEN",
    //     xsrfHeaderName: "X-XSRF-TOKEN",
    //     maxContentLength: -1,
    //     maxBodyLength: -1,
    //     env: {},
    //     headers: {
    //       Accept: "application/json, text/plain, */*",
    //       "Content-Type": "application/json",
    //       "Bliq-API-Key":
    //         " 6R8V7GqNvJwT6Ary8wcEzNUB+UAEY61GNh7PmdNJA7n/+r3ApMLsEX7WthcdZlqV",
    //     },
    //     method: "post",
    //     url: "https://dev.bliqmsg.com/api/v1/sms/send/message",
    //     data: '{"text":"1","phone":"8483122221","send_to":"8482165040","media":["https://example.com/x5rff.png"],"inbound":"false"}',
    //   },
    //   request: {},
    // };

    // return response;
  } catch (error) {
    console.error("Error Fetching Carrier Response", error);
    return error;
  }
};

export const deleteCarrier = async (id, token) => {
  try {
    const response = await axios.delete(`${BASE_URL}${CARRIER_APPEND}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error(`Error deleting carrier with ID: ${id}`, error);
    throw error;
  }
};

export const getCarrier = async (id, token) => {
  try {
    const response = await axios.get(`${BASE_URL}${CARRIER_APPEND}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching carrier details:", error);
    throw error;
  }
};
