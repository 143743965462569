import React from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SmsIcon from "@mui/icons-material/Sms";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const CarrierDropdown = ({ currentCarrier, onCarrierChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    onCarrierChange(value);
    handleClose();
  };

  const getCarrierLabel = () => {
    switch (currentCarrier) {
      case "SMS":
        return "SMS";
      case "WHATSAPP":
        return "WhatsApp";
      default:
        return currentCarrier;
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography
        variant="body2"
        sx={{
          color: "black",
          fontSize: "14px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleClick}
      >
        via{" "}
        <Box
          component="span"
          sx={{
            display: "inline-flex",
            alignItems: "center",
            ml: 0.5,
            mr: 0.5,
            color: "#1976d2",
          }}
        >
          {getCarrierLabel()}
        </Box>
        <ArrowDropDownIcon fontSize="small" sx={{ color: "#1976d2" }} />
      </Typography>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiMenu-paper": {
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          },
          marginTop: "-80px",
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick("SMS")}>
          <SmsIcon fontSize="small" sx={{ mr: 1 }} />
          SMS
        </MenuItem>
        <MenuItem onClick={() => handleMenuItemClick("WHATSAPP")}>
          <WhatsAppIcon fontSize="small" sx={{ mr: 1 }} />
          WhatsApp
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default CarrierDropdown;
