import axios from "../../core/axios";
import { MESSAGE_ACK } from "constants/Endpoints"
import { FORWARD_MESSAGE } from "constants/Endpoints"
import { MESSAGE } from "constants/Endpoints"
import { CHAT_STRING } from "constants/Endpoints"
import { CHAT_MESSAGE } from "constants/Endpoints"

export const MessageAPI = {
    getChatMessages : async({token,chatId,page=0,size=60}) => {
        return await axios.get(CHAT_MESSAGE(chatId),{ params: { page,size }},{
            headers:{
                'Authorization': 'Bearer ' + token                
            },
        })
    },
    createMessage : async({token,payload}) => {
        return await axios.post(CHAT_STRING + MESSAGE,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },
    markMessageStatus : async({token,payload}) => {
        return await axios.post(MESSAGE_ACK,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },
    forwardMessage : async({token,payload}) => {
        return await axios.post(FORWARD_MESSAGE,JSON.stringify(payload),{
            headers:{
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',                
            }
        })
    },
}