import { Button, Typography } from "@mui/material";
import EnhancedTable from "components/CommonUI/EnhancedTable";
import MDBox from "lib/components/MDBox";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SyncrAPI } from "services/Syncr/SyncrAPI";
import { v4 as uuidv4 } from "uuid"; // Import UUID library

const columns = [
    { id: 1, key: "api_key", label: "Key" },
    { id: 2, key: "active", label: "Active" },
    { id: 3, key: "app_version", label: "App version" },
];
const GenerateSyncrCode = ({phone}) => {
    
    const [apiKey, setApiKey] = useState("");
    const [allKeys,setAllKeys] = useState([]);
    const [generatedCode, setGeneratedCode] = useState("");
    const [timeLeft, setTimeLeft] = useState(180); // Countdown timer (in seconds)
    const [isCodeGenerating,setIsCodeGenerating] = useState(false);

    const token = useSelector(state => state.user.token);
    const userId = useSelector(state => state.user.id);

    const generateCode = () => {
        const code = Math.floor(10000000 + Math.random() * 90000000); // Random 8-digit number
        setGeneratedCode(code);
        const ky = uuidv4();
        setApiKey(ky); // Generate a unique API key
        setTimeLeft(180); // Reset countdown
        const payload = {
            code,
            phone : phone.phone,
            phoneId : phone.phoneId,
            apiKey: ky,
            userId
        }
        SyncrAPI.saveGeneratedCode({
            token,payload
        }).then(res => {
            // successfully saved
        }).catch(ex => {
            console.log(ex);
        })
    } 
    
    useEffect(() => {
        if(token && phone?.phone){
            SyncrAPI.getApiKeysForPhone({token,phone : phone?.phone}).then(res => {
                const rws = res?.data?.map(itm => {
                    return{
                        api_key : itm?.api_key,
                        active: Boolean(itm?.active) ? "Yes" : "No",
                        app_version : itm?.app_version
                    }
                });
                setAllKeys(rws);
            }).catch(e => {
                console.log(e?.response?.data?.message);
            })
        }
    },[token,phone?.phone]);

  // Update the code every 1 minute if a phone number is selected
    useEffect(() => {
        if (phone?.phoneId && isCodeGenerating) {
            generateCode(); // Generate the initial code

            const interval = setInterval(() => {
                setTimeLeft((prevTime) => {
                  if (prevTime <= 1) {
                    generateCode(); // Generate new code when time reaches 0
                    return 180;
                  }
                  return prevTime - 1;
                });
              }, 1000); // Update timer every second

            // Cleanup interval on component unmount or when selection changes
            return () => clearInterval(interval);
        }
    }, [phone?.phoneId,phone?.phone,token,isCodeGenerating,userId]);    

    return(
        <MDBox display="flex" flexDirection="column" width="100%">
            <MDBox display="flex" flexDirection="column" mb={1}>
                <EnhancedTable
                    showPagination={false}
                    selectEnabled={false}
                    showFilter={false}
                    rows={allKeys}
                    columns={columns}
                />
            </MDBox>
            <MDBox display="flex">
                {!isCodeGenerating && <Button
                    variant="contained"
                    sx={{
                        maxWidth : "30%",
                        borderRadius: "0px",
                        color: "white!important",
                        border: "unset",
                        marginLeft: "12px",
                        height: "32px",
                        minHeight: "unset"
                    }}
                    onClick={() => setIsCodeGenerating(true)}
                    >
                    Login to syncr app
                </Button>}
            </MDBox>
            {isCodeGenerating && 
                <MDBox display="flex" width="100%" justifyContent="center" flexDirection="column" alignItem="center"> 
                    <Typography fontWeight={600} fontSize={22}>Login Code: {generatedCode}<Typography fontSize={16} fontWeight={400}>(Enter this code in your BLIQ Syncr app.)</Typography></Typography>
                    <Typography>Code expires in : {timeLeft} seconds</Typography>
                </MDBox>
            }
        </MDBox>        
    )
}
export default GenerateSyncrCode;