import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import {useState } from "react";
import AppModal from "ui/AppModal";
import EditIcon from "@mui/icons-material/Edit";
import EditCredForm from "../EditCredForm";
import { useSelector } from "react-redux";
import { Button, Tooltip } from "@mui/material";
import { Delete, Settings } from "@mui/icons-material";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import AssignPhoneV2 from "components/Phone/AssignPhoneV2";
import { ROLE } from "constants/AppConstants";
import VoipCallConfig from "./VoipCallConfig";
import { PhoneAPI } from "services/PhoneAPI";
import AppConstants from "constants/AppConstants";
import Accordian from "components/util/Accordian";
import CellCallConfig from "./Cell/CellCallConfig";
import { OperationStatus } from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import GenerateSyncrCode from "components/Syncr/GenerateSyncCode";

const PHONE_ACTION = {
  edit: 'edit',
  delete : 'delete',
  none : 'none',
  assign : 'assign',
  config : 'config'
}
const PhoneActions = ({ phone, onPhoneAssigned,onPhoneEdited = () => {},onDeleted, onCellConfigUpdated, onDisaUpdated = () => {} }) => {

  const [action, setAction] = useState(PHONE_ACTION.none);

  const role = useSelector(state => state.user.role);
  const token = useSelector(state => state.user.token);

  // console.log(phone)

  const deletePhoneHandler = () => {
    Swal.fire({
      title: 'Delete this number?',
      text: "Deleting this number will delete all the data including conversation history for this phone number.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Yes, delete it!",
      customClass: {
        container : {
          zIndex : '10000 !important'
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        PhoneAPI.deletePhone({phoneId : phone.phoneId, token}).then(res => {
          if(res?.data?.status === OperationStatus.success){
            toast.success(res?.data?.message);
            onDeleted(phone?.phoneId);
          }
        }).catch(e => toast.error(e?.response?.data?.message))
      }
    })
  }
  const onPhoneAssignHandler = (res) => {
    if (res) {
      setAction(-1);
      onPhoneAssigned(res);
    }
  };
  const onCredentialEdited = (res) => {
    if(res){
        setAction(-1);
        onPhoneEdited(res);
    }
  }

  return (
    <>
      {role === ROLE.SUPER_ADMIN && 
        <>
          <Tooltip title="Edit the number."> 
            <EditIcon
              onClick={() => {setAction(PHONE_ACTION.edit)}}
              style={{ marginRight: "8px", cursor: "pointer", width:'1.25rem', height:'1.25rem' }}
            />
            </Tooltip>
            <Tooltip title="Delete this number">
              <Delete
                onClick={deletePhoneHandler}
                style={{ marginRight: "8px", cursor: "pointer", width:'1.25rem', height:'1.25rem' }}
              />
            </Tooltip>
        </>
      }
      <Tooltip title="Config">
        <Settings
          key={phone.phone}
          onClick={() => {
            setAction(PHONE_ACTION.config);
          }}
          sx={{marginRight: "8px", width:'1.25rem', height:'1.25rem' }}
          style={{ cursor: "pointer", fontSize: "40px!important" }}
        />
      </Tooltip>
      <Tooltip title="Assign users to phone number">
        <AssignmentIndIcon
          key={phone.phone}
          onClick={() => {
              setAction(PHONE_ACTION.assign);
          }}
          sx={{ width:'1.25rem', height:'1.25rem' }}
          style={{ cursor: "pointer", fontSize: "40px!important" }}
        />
      </Tooltip>
      {action === PHONE_ACTION.assign && (
        <AssignPhoneV2 onClose={() => setAction(PHONE_ACTION.none)}/>
      )}
      {action === PHONE_ACTION.edit && role === ROLE.SUPER_ADMIN && (
        <AppModal
          onModalClose={() => {
            setAction(PHONE_ACTION.none);
          }}
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'35%',
                left: '65%'
              }
            }
          }
          customStyle = {{ 
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}            
          heading={"Edit number"}
        >
            <EditCredForm phone={phone} onCredentialEdited={onCredentialEdited}/>
        </AppModal>
      )}  

      {action === PHONE_ACTION.config && (
        <AppModal
          onModalClose={() => {
            setAction(PHONE_ACTION.none);
          }}
          rootStyle = {
            {
              "&.MuiModal-root":{
                width:'40%',
                left: '60%'
              }
            }
          }
          customStyle = {{ 
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}            
          heading={AppConstants.MOBO_APP_CELL_CALL}
        >
          <Accordian style={{margin: "12px"}} heading={"Voip call settings"}>
            <VoipCallConfig key={"voip_call_" + phone.phoneId} config={phone?.cellConfig} phoneId={phone.phoneId} onDisaUpdated={onDisaUpdated}/>
          </Accordian>
          <Accordian style={{margin: "12px"}} heading={"Cellular call settings"}>
            <CellCallConfig key={"call_" + phone.phoneId} config={phone?.cellConfig} phoneId={phone.phoneId} onCellConfigUpdated={onCellConfigUpdated}/>
          </Accordian>  
          <MDBox sx={{margin : "12px"}} display="flex" flexDirection="column">
            <GenerateSyncrCode phone={phone}/>
          </MDBox>
        </AppModal>
      )}  

    </>
  );
};
export default PhoneActions;
