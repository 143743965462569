import { SideBarIcons } from "pages/Chat/ChatStyles";
import { FlexContainer } from "pages/Chat/ChatStyles";
import { StaticSideBar } from "pages/Chat/ChatStyles";
import ChatIcon from "@mui/icons-material/Chat";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { useEffect, useState } from "react";
import GroupsIcon from "@mui/icons-material/Groups";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Badge, Tooltip } from "@mui/material";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useCallback } from "react";
import { chatAction } from "store/chat-slice";
import { chatVariablesAction } from "store/chat-variables-slice";
import { Forum } from "@mui/icons-material";
import MDBox from "lib/components/MDBox";
import { ichatVariablesAction } from "store/IChat/Ichat-variables-slice";
import { VIEWS } from "constants/ChatConstants";
import { memo } from "react";
import { IVIEWS } from "constants/ChatConstants";

const ChatSideBar = ({ view }) => {
  const [currentSelected, setCurrentSelected] = useState(view);
  const dispatch = useDispatch();

  //Flag when merge view is on:::
  const isMergeView = useSelector((state) => state.app.mergeViewActive);

  const currentActiveView = useSelector(
    (state) => state.ichatVariables.activeView
  );
  const iChat = useSelector((state) => state.chatVariables.iChat);
  const unreadCount = useSelector((state) => state.chat.unreadCount);
  const totalUnreadDmCount = useSelector(
    (state) => state.Ichat.totalUnreadDmCount
  );
  const totalUnreadGroupCount = useSelector(
    (state) => state.Ichat.totalUnreadGroupCount
  );
  const currUser = useSelector((state) => state.user);

  const allHandler = useCallback(() => {
    setCurrentSelected(VIEWS.NORMAL_VIEW);
    dispatch(chatVariablesAction.setIchat(false));
    const searchState = {
      search: false,
      pattern: "",
    };
    dispatch(chatVariablesAction.recipientSearch(searchState));
    if (!isMergeView) {
      dispatch(chatAction.setCurrentActiveView(VIEWS.NORMAL_VIEW));
    } else {
      dispatch(chatAction.setCurrentActiveView(VIEWS.MERGED_VIEW));
    }
  }, [isMergeView, dispatch]);

  const handleScheduleView = () => {
    //TODO:
    toast.info("This feature is under implementation");
    return;
    // dispatch(chatVariablesAction.setIchat(false));
    // setCurrentSelected(VIEWS.SCHEDULED_VIEW);
    // dispatch(chatAction.setCurrentActiveView(VIEWS.SCHEDULED_VIEW));
    // dispatch(chatAction.setCurrentActiveRecipient(undefined));
  };

  const handleHippaView = () => {
    //TODO:
    toast.info("This feature is under implementation");
    return;
    // dispatch(chatVariablesAction.setIchat(false));
    // setCurrentSelected(VIEWS.HIPAA_VIEW);
    // dispatch(chatAction.setCurrentActiveView(VIEWS.HIPAA_VIEW));
    // dispatch(chatAction.setCurrentActiveRecipient(undefined));
  };

  const unreadClickHandler = useCallback(() => {
    dispatch(chatVariablesAction.setIchat(false));
    const searchState = {
      search: false,
      pattern: "",
    };
    dispatch(chatVariablesAction.setIchat(false));
    if (currentActiveView !== VIEWS.UNREAD_VIEW) {
      dispatch(chatAction.setCurrentActiveView(VIEWS.UNREAD_VIEW));
    }
    dispatch(chatAction.setCurrentActiveRecipient(undefined));
    setCurrentSelected(VIEWS.UNREAD_VIEW);
    // onEventPerform('UNREAD_VIEW',true);
  }, [unreadCount, currentActiveView]);

  useEffect(() => {
    setCurrentSelected(view);
  }, [view]);

  return (
    <StaticSideBar>
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        id={"tele__side__bar"}
      >
        <FlexContainer fxLayout="column" justifyContent="flex-start">
          <Tooltip title="All Chats" placement="right">
            <SideBarIcons
              currentSelected={
                currentSelected === VIEWS.NORMAL_VIEW ||
                currentSelected === VIEWS.MERGED_VIEW
              }
              onClick={allHandler}
            >
              <ChatIcon className="sidebar-icons" />
            </SideBarIcons>
          </Tooltip>
          <Tooltip title="Unread Messages" placement="right">
            <SideBarIcons
              currentSelected={currentSelected === VIEWS.UNREAD_VIEW}
              onClick={unreadClickHandler}
            >
              <Badge color="secondary" badgeContent={unreadCount}>
                <MarkChatUnreadIcon className="sidebar-icons" />
              </Badge>
            </SideBarIcons>
          </Tooltip>
          {/** Scheduled messages view */}
          {/* <Tooltip title="View scheduled messages" placement="right">
                <SideBarIcons currentSelected={currentSelected === VIEWS.SCHEDULED_VIEW} onClick={handleScheduleView}>
                    <WatchLaterIcon className="sidebar-icons"/>
                </SideBarIcons>
              </Tooltip>             */}
          {!isMergeView && (
            <Tooltip title="Broadcasts" placement="right">
              <SideBarIcons
                onClick={() => {
                  setCurrentSelected(VIEWS.GROUP_VIEW);
                  dispatch(chatVariablesAction.setIchat(false));
                  dispatch(chatAction.setCurrentActiveView(VIEWS.GROUP_VIEW));
                }}
                currentSelected={currentSelected === VIEWS.GROUP_VIEW}
              >
                <CampaignIcon className="sidebar-icons group-side-icon" />
              </SideBarIcons>
            </Tooltip>
          )}
          {/* {//TODO:: Hipaa view*/}
          {/* {currUser.company?.hipaa_compliance && (
            <>
              <Tooltip title="Waiting for HIPPA consent" placement="right">
                <SideBarIcons
                  onClick={handleHippaView}
                  currentSelected={currentSelected === VIEWS.HIPAA_VIEW}
                >
                  <CheckBoxIcon className="sidebar-icons group-side-icon" />
                </SideBarIcons>
              </Tooltip>
            </>
          )} */}
        </FlexContainer>
      </MDBox>
      {/* <MDBox
        id={"ic__side__bar"}
        sx={{
          position: "relative",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MDBox sx={{ position: "fixed", bottom: "10px" }}>
          <Tooltip title="PMs" placement="right">
            <SideBarIcons
              onClick={() => {
                dispatch(
                  ichatVariablesAction.setActiveView(IVIEWS.INORMAL_VIEW)
                );
                dispatch(chatVariablesAction.setIchat(true));
              }}
              currentSelected={
                currentActiveView === IVIEWS.INORMAL_VIEW && iChat === true
              }
            >
              <Badge color="primary" badgeContent={totalUnreadDmCount}>
                <Forum className="sidebar-icons group-side-icon" />
              </Badge>
            </SideBarIcons>
          </Tooltip>
          <Tooltip title="Groups" placement="right">
            <SideBarIcons
              onClick={() => {
                dispatch(chatVariablesAction.setIchat(true));
                dispatch(
                  ichatVariablesAction.setActiveView(IVIEWS.IGROUP_VIEW)
                );
              }}
              currentSelected={
                currentActiveView === IVIEWS.IGROUP_VIEW && iChat === true
              }
            >
              <Badge color="primary" badgeContent={totalUnreadGroupCount}>
                <GroupsIcon className="sidebar-icons group-side-icon" />
              </Badge>
            </SideBarIcons>
          </Tooltip>
        </MDBox>
      </MDBox> */}
    </StaticSideBar>
  );
};
export default memo(ChatSideBar);
