import { Typography, Box, IconButton, Tooltip, Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import MDBox from "lib/components/MDBox";

const ParamsAndQuery = ({ params, queries, openModal }) => {
  return (
    <MDBox margin={"16px 10px"}>
      <Box display="flex" alignItems="center" marginBottom={"12px"}>
        <Typography variant="h6" fontWeight="500">
          Params and Query
        </Typography>
        <Tooltip title="These are the parameters and queries extracted from the entered URL. Configure them as needed.">
          <IconButton size="small" sx={{ marginLeft: "8px" }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <MDBox display="flex" gap={"16px"}>
        {/* Params Section */}
        <MDBox
          flex={1}
          padding={"16px"}
          width={"100%"}
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          sx={{
            height: "250px", // Fixed height
            overflowY: "auto", // Scrollable content
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={"500"}
            marginBottom={"8px"}
          >
            Params
          </Typography>
          {params.length > 0 ? (
            params.map((param, index) => (
              <MDBox
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={"12px"}
                padding={"10px"}
                border={"1px solid #e0e0e0"}
                borderRadius={"8px"}
                position="relative"
                sx={{
                  backgroundColor: "#f0f8ff",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#e6f7ff",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#333",
                  }}
                >
                  {param.key}
                </Typography>
                {param.configured ? (
                  <IconButton
                    onClick={() => openModal("params", param.key)}
                    size="small"
                    sx={{
                      color: "#007bff",
                      "&:hover": {
                        color: "#0056b3",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <Button
                    onClick={() => openModal("params", param.key)}
                    className="configure-button"
                    variant="text"
                    size="small"
                    color="primary"
                    // sx={{
                    //   backgroundColor: "#007bff",
                    //   color: "#fff",
                    //   textTransform: "none",
                    //   fontWeight: "bold",
                    //   borderRadius: "4px",
                    //   "&:hover": {
                    //     backgroundColor: "#0056b3",
                    //   },
                    // }}
                  >
                    Configure
                  </Button>
                )}
              </MDBox>
            ))
          ) : (
            <Typography color="textSecondary" fontSize={"14px"}>
              No params found
            </Typography>
          )}
        </MDBox>

        {/* Query Section */}
        <MDBox
          flex={1}
          padding={"16px"}
          width={"100%"}
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          sx={{
            height: "250px", // Fixed height
            overflowY: "auto", // Scrollable content
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={"500"}
            marginBottom={"8px"}
          >
            Query
          </Typography>
          {queries.length > 0 ? (
            queries.map((query, index) => (
              <MDBox
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={"12px"}
                padding={"10px"}
                border={"1px solid #e0e0e0"}
                borderRadius={"8px"}
                position="relative"
                sx={{
                  backgroundColor: "#f0f8ff",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#e6f7ff",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#333",
                  }}
                >
                  {query.key}
                </Typography>
                {query.configured ? (
                  <IconButton
                    onClick={() => openModal("queries", query.key)}
                    size="small"
                    sx={{
                      color: "#007bff",
                      "&:hover": {
                        color: "#0056b3",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                ) : (
                  <Button
                    onClick={() => openModal("queries", query.key)}
                    className="configure-button"
                    variant="text"
                    size="small"
                    color="primary"
                    // sx={{
                    //   backgroundColor: "#007bff",
                    //   color: "#fff",
                    //   textTransform: "none",
                    //   fontWeight: "bold",
                    //   borderRadius: "4px",
                    //   "&:hover": {
                    //     backgroundColor: "#0056b3",
                    //   },
                    // }}
                  >
                    Configure
                  </Button>
                )}
              </MDBox>
            ))
          ) : (
            <Typography color="textSecondary" fontSize={"14px"}>
              No queries found
            </Typography>
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default ParamsAndQuery;
