import { Add, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Chip,
  createFilterOptions,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MDBox from "lib/components/MDBox";

// Assuming you have a custom AppModal component
import AppModal from "ui/AppModal"; // <-- adjust the import path as needed

import { useCallback, useEffect, useState, memo } from "react";
import { TwitterPicker } from "react-color";
import useHttp from "hooks/use-http";
import { createCompanyTag } from "api/company";
import { useDispatch, useSelector } from "react-redux";
import { ConversationAPI } from "services/ConversationAPI";
import { messageAction } from "store/message-slice";
import { appAction } from "store/app-slice";
import { TAG_STATE } from "constants/AppConstants";
import { toast } from "react-toastify";

const tagAlreadyUsed = (tags, name) => {
  const filtered = tags?.filter((tag) => tag?.tag?.name === name);
  return filtered.length > 0;
};

const Tags = ({ onClose, onTagChange, showTagInput, message }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.user);
  const availableTags = useSelector((state) => state.app.companyTags);
  const recipientId = useSelector(
    (state) => state.chat.current_active_recipient?.id
  );
  const phoneId = useSelector((state) => state.chat.current_active_phone.id);

  // For listing existing tags on the message
  const [tagsGiven, setTagsGiven] = useState(message?.tags || []);

  // For storing all company tags in the modal’s dropdown
  const [companyTags, setCompanyTags] = useState([]);

  // For creating a brand new tag
  const [newTag, setNewTag] = useState(null);

  // Keep track of whether the modal is open or not
  const [openModal, setOpenModal] = useState(false);

  // Tag state machine (INITIAL, CREATING, END, etc.)
  const [currentTagState, setCurrentTagState] = useState(TAG_STATE.INITIAL);

  const filter = createFilterOptions();

  // Hook for creating a company-wide tag
  const {
    sendRequest: createTagFn,
    data: createdTag,
    status: createTagStatus,
    error: createTagError,
  } = useHttp(createCompanyTag, false, false);

  // ---------------
  //   Delete Tag
  // ---------------

  const handleDelete = useCallback(
    (chipToDelete) => {
      console.log("Remove Tag from Tags.js", chipToDelete);
      //Call the endpoint and delete from DB as well
      ConversationAPI.removeTag({
        token: currentUser.token,
        tag: {
          ...chipToDelete,
          message: {
            id: message.id,
            sendTo: message.sendTo,
            sendByPhone: message.sendByPhone,
          },
          createdBy: currentUser.id,
        },
      })
        .then((res) => {
          dispatch(
            messageAction.removeTag({
              recipientId,
              messageId: message.id,
              id: chipToDelete?.id,
            })
          );
        })
        .catch((e) => {});

      const newChips = tagsGiven?.filter(
        (chip) => chip?.tag?.name !== chipToDelete?.tag?.name
      );
      setTagsGiven(newChips);
      if (newChips.length == 0) {
        setCurrentTagState(TAG_STATE.INITIAL);
      }
    },
    [currentUser, tagsGiven, message]
  );

  // ----------------------------------------------------
  //  Save a brand-new tag to DB (company + message link)
  // ----------------------------------------------------
  const saveTag = useCallback(() => {
    if (!newTag) return;

    // Step 1: Create the company-level tag
    createTagFn({
      ...newTag,
      token: currentUser.token,
      company: {
        company_id: currentUser.company?.company_id,
      },
    });
  }, [newTag, currentUser, createTagFn]);

  // -------------
  //  Color Picker
  // -------------
  const handleChangeComplete = (color) => {
    setNewTag((prev) => ({
      ...prev,
      tag: {
        ...prev.tag,
        color: color.hex,
      },
    }));
  };

  // -----------------------------------------------------------------
  //  Called when user selects or creates a tag from the Autocomplete
  // -----------------------------------------------------------------
  const onTagChangeHandler = useCallback(
    (newValue) => {
      if (!newValue) return;

      // If the user selected an existing tag with an ID
      if (newValue.tagId) {
        let val = "";
        if (typeof newValue === "string") {
          val = newValue;
        } else if (newValue && newValue.inputValue) {
          // user typed something new, but that would be handled below
          val = { name: newValue.inputValue };
        } else {
          val = newValue;
        }

        if (val.name !== "" && !tagAlreadyUsed(tagsGiven, val.name)) {
          const payload = {
            tag: val,
            message: {
              id: message.id,
              sendTo: message.sendTo,
              sendByPhone: message.sendByPhone,
            },
            createdBy: currentUser.id,
          };
          ConversationAPI.createTag({ token: currentUser.token, payload })
            .then((response) => {
              dispatch(
                messageAction.newTag({
                  recipientId,
                  phoneId,
                  messageId: message.id,
                  tag: response?.data?.tag,
                })
              );
              onTagChange?.();
            })
            .catch((e) => console.log(e));
        } else {
          toast.error("Tag already used in the current message");
        }
        // If user selected an existing tag or a tag that’s already used,
        // we can just close modal now
        setOpenModal(false);
        setCurrentTagState(TAG_STATE.INITIAL);
      } else {
        // user typed a brand new tag
        const cTag = {
          tag: {
            name: newValue.inputValue,
          },
        };
        setNewTag(cTag);
      }
    },
    [
      tagsGiven,
      dispatch,
      currentUser,
      recipientId,
      phoneId,
      message,
      onTagChange,
    ]
  );

  // ----------------------------------------------------------
  //  After "createTagFn" finishes, link it to message & close
  // ----------------------------------------------------------
  // --------------------------------------------------------------------------------
  // 2) useEffect checks when createTagFn finishes (createTagStatus === "completed")
  // --------------------------------------------------------------------------------
  useEffect(() => {
    // "completed" means your useHttp call has finished (success or fail)
    if (createTagStatus === "completed") {
      if (createdTag) {
        // We successfully created the tag at the *company* level
        // Now attach it to the message
        const payload = {
          tag: createdTag,
          message: {
            id: message.id,
            sendTo: message.sendTo,
            sendByPhone: message.sendByPhone,
          },
          createdBy: currentUser.id,
        };

        ConversationAPI.createTag({ token: currentUser.token, payload })
          .then((response) => {
            // Tag attached to message successfully
            // Dispatch Redux or update local state as needed
            dispatch(
              messageAction.newTag({
                recipientId,
                phoneId,
                messageId: message.id,
                tag: response?.data?.tag,
              })
            );
            // Possibly remove newTag from local state, close modal, etc.
            onTagChange?.();
            setOpenModal(false);
            setNewTag(null);
            setCurrentTagState(TAG_STATE.INITIAL);
            toast.success("Tag created & linked to message!");
          })
          .catch((err) => {
            // The "link to message" call failed
            toast.error("Failed to link the new tag to the message.");
            // Remove it from local or revert if you had inserted it there
            setTagsGiven((prev) =>
              prev.filter((item) => item?.tag?.name !== createdTag.name)
            );
          });
      } else {
        // We failed to create at the *company* level
        // Possibly you have an error in createTagError
        toast.error("Failed to create the new tag at the company level.");

        // Remove it from local if you had added it
        // Usually you'd filter out by `newTag.tag.name`:
        setTagsGiven((prev) =>
          prev.filter((item) => item?.tag?.name !== newTag?.tag?.name)
        );

        // or reset states:
        setNewTag(null);
        setCurrentTagState(TAG_STATE.INITIAL);
      }
    }
  }, [createTagStatus, createdTag, createTagError]);

  // Keep local tags in sync with global store
  useEffect(() => {
    if (availableTags) {
      setCompanyTags(availableTags);
    }
  }, [availableTags]);

  // Sync whenever `message?.tags` changes
  useEffect(() => {
    if (message?.tags) {
      setTagsGiven(message.tags);
    }
  }, [message?.tags]);

  useEffect(() => {
    if (showTagInput) {
      setOpenModal(true);
    }
  }, [showTagInput]);

  // ==============================
  //       RENDER COMPONENT
  // ==============================
  return (
    <>
      {/*
        ==========================================
          AppModal for the "Create/Select Tag"
        ==========================================
      */}
      {openModal && (
        <AppModal
          onModalClose={() => {
            setOpenModal(false);
            setNewTag(null);
            setCurrentTagState(TAG_STATE.INITIAL);
            onClose?.();
          }}
          heading="Add or Create a Tag"
        >
          <MDBox display="flex" flexDirection="column">
            {/** 
      1) Show existing tags in the modal, 
         so user can delete them from here, 
         just like the recipient tag UI.
    */}
            <MDBox display="flex" flexWrap="wrap" mb={2}>
              {Array.isArray(tagsGiven) &&
                tagsGiven.map((item, index) => (
                  <Chip
                    key={`tag_${item.tag?.name}_${index}`}
                    label={item?.tag?.name}
                    sx={{ m: 1, height: "24px" }}
                    style={
                      item?.tag?.color
                        ? { backgroundColor: item.tag.color, color: "#fff" }
                        : {}
                    }
                    onDelete={() => handleDelete(item)} // same handleDelete fn you already have
                  />
                ))}
            </MDBox>

            {/**
      2) Autocomplete for selecting existing tags or creating a new one
    */}
            <Autocomplete
              disableClearable
              options={companyTags || []}
              getOptionLabel={(option) => option.name}
              autoHighlight
              sx={{ minWidth: 150, maxWidth: 300, mb: 2 }}
              renderOption={(props, option) => {
                const { name, color } = option;
                return (
                  <span
                    {...props}
                    style={{ backgroundColor: color, margin: "4px" }}
                  >
                    {name}
                  </span>
                );
              }}
              onChange={(event, newValue) => onTagChangeHandler(newValue)}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `+ Create "${inputValue}"`,
                  });
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" placeholder="tag" />
              )}
            />

            {/**
      3) If user typed a completely new tag,
         show the color picker & Save button
    */}
            {newTag && (
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  mb: 2,
                  borderRadius: 2,
                  backgroundColor: "#fafafa",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                  maxWidth: 400,
                }}
              >
                {/* Section Title */}
                <MDBox>
                  <Typography variant="h6" sx={{ mb: 1, fontWeight: 600 }}>
                    Create a New Tag
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Pick a name and color for your tag, then click “Save.”
                  </Typography>
                </MDBox>

                {/* Preview of the new tag as a Chip */}
                <Chip
                  label={newTag?.tag?.name}
                  sx={{
                    alignSelf: "flex-start",
                    height: 28,
                    fontWeight: 500,
                  }}
                  style={
                    newTag?.tag?.color
                      ? {
                          backgroundColor: newTag.tag.color,
                          color: "#ffffff",
                        }
                      : {}
                  }
                />

                {/* Color Picker */}
                <MDBox display="flex" justifyContent="center">
                  <TwitterPicker
                    color={newTag?.tag?.color || "#000"}
                    onChangeComplete={handleChangeComplete}
                    styles={{ default: { card: { boxShadow: "none" } } }}
                  />
                </MDBox>

                {/* Save and Cancel Buttons */}
                <MDBox display="flex" justifyContent="flex-end" gap={1}>
                  <Button
                    variant="text"
                    sx={{
                      color: "error.main",
                      "&:hover": { color: "error.main" },
                    }}
                    onClick={() => {
                      // If you need a "cancel" flow:
                      setNewTag(null);
                      setCurrentTagState(TAG_STATE.INITIAL);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "info.main",
                      color: "info.contrastText",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      saveTag();
                    }}
                  >
                    Save
                  </Button>
                </MDBox>
              </Paper>
            )}
          </MDBox>
        </AppModal>
      )}
    </>
  );
};

export default memo(Tags);
