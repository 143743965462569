import {
  Typography,
  Box,
  IconButton,
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";

const AUTH_OPTIONS = [
  { key: "NONE", label: "No Auth" },
  { key: "BASIC", label: "Basic Auth" },
  { key: "BEARER", label: "Bearer Token" },
];

const HeadersAndAuth = ({ headers, auth, setCarrierData, openModal }) => {
  const [authType, setAuthType] = useState(auth?.type || "NONE");
  const [username, setUsername] = useState(auth?.username || "");
  const [password, setPassword] = useState(auth?.password || "");
  const [token, setToken] = useState(auth?.token || "");
  const [showPassword, setShowPassword] = useState(false);

  const handleAuthTypeChange = (e) => {
    const selectedAuthType = e.target.value;
    setAuthType(selectedAuthType);

    // Reset fields when switching auth type
    setUsername("");
    setPassword("");
    setToken("");

    // Update carrierData with the selected auth type
    setCarrierData((prev) => ({ ...prev, auth: { type: selectedAuthType } }));
  };

  const validateAuth = () => {
    if (authType === "BASIC") {
      if (!username.trim()) {
        toast.error("Username is required for Basic Auth.");
        return false;
      }
      if (!password.trim()) {
        toast.error("Password is required for Basic Auth.");
        return false;
      }
    } else if (authType === "BEARER") {
      if (!token.trim()) {
        toast.error("Bearer Token is required.");
        return false;
      }
    }
    return true;
  };

  const handleSaveAuth = () => {
    if (!validateAuth()) {
      return;
    }

    const updatedAuth = { type: authType };

    if (authType === "BASIC") {
      updatedAuth.username = username;
      updatedAuth.password = password;
    } else if (authType === "BEARER") {
      updatedAuth.token = token;
    }

    setCarrierData((prev) => ({ ...prev, auth: updatedAuth }));

    toast.success("Authentication details saved successfully.");
  };

  const handleDeleteHeader = (key) => {
    setCarrierData((prev) => ({
      ...prev,
      headers: prev.headers.filter((header) => header.key !== key),
    }));
    toast.success(`Header "${key}" deleted successfully.`);
  };

  return (
    <MDBox margin={"16px 10px"}>
      <Box display="flex" alignItems="center" marginBottom={"12px"}>
        <Typography variant="h6" fontWeight="500">
          Headers and Auth Config
        </Typography>
        <Tooltip title="Specify the headers and authentication details required for the request.">
          <IconButton size="small" sx={{ marginLeft: "1px" }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <MDBox display="flex" gap={"16px"}>
        {/* Headers Section */}
        <MDBox
          flex={1}
          padding={"16px"}
          width={"100%"}
          border={"1px solid #ccc"}
          borderRadius={"8px"}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography
              variant="subtitle1"
              fontWeight={"500"}
              marginBottom={"8px"}
            >
              Headers
            </Typography>
            <Button
              onClick={() => openModal(null)} // Open modal for adding a new header
              variant="text"
              size="small"
              color="primary"
              // sx={{
              //   backgroundColor: "#007bff",
              //   color: "#fff",
              //   fontWeight: "bold",
              //   borderRadius: "4px",
              //   "&:hover": {
              //     backgroundColor: "#0056b3",
              //   },
              // }}
            >
              + Add Header
            </Button>
          </Box>
          {headers.length > 0 ? (
            headers.map((header, index) => (
              <MDBox
                key={index}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom={"8px"}
                padding={"8px"}
                border={"1px solid #e0e0e0"}
                borderRadius={"4px"}
                position="relative"
                sx={{
                  backgroundColor: "#f0f8ff",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#e6f7ff",
                  },
                }}
              >
                <Typography>{header.key}</Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <IconButton
                    onClick={() => openModal(header.key)} // Open modal for editing an existing header
                    size="small"
                    sx={{
                      color: "#007bff",
                      "&:hover": {
                        color: "#0056b3",
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteHeader(header.key)} // Delete header
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </MDBox>
            ))
          ) : (
            <Typography color="textSecondary" fontSize={"14px"}>
              No headers defined
            </Typography>
          )}
        </MDBox>

        {/* Auth Config Section */}
        <MDBox
          flex={1}
          padding={"16px"}
          border={"1px solid #ccc"}
          borderRadius={"8px"}
        >
          <Typography
            variant="subtitle1"
            fontWeight={"500"}
            marginBottom={"8px"}
          >
            Auth Config
          </Typography>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel
              id="auth-type-label"
              sx={{
                background: "white",
                px: 0.3,
              }}
            >
              Authentication Type
            </InputLabel>
            <Select
              labelId="auth-type-label"
              value={authType}
              onChange={handleAuthTypeChange}
              sx={{ height: "50px" }}
            >
              {AUTH_OPTIONS.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {authType === "BASIC" && (
            <MDBox display="flex" flexDirection="column" gap={2}>
              <MDInput
                label="Username"
                fullWidth
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <MDInput
                label="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
          )}

          {authType === "BEARER" && (
            <MDInput
              label="Bearer Token"
              fullWidth
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
          )}

          <MDBox mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleSaveAuth}
              variant="contained"
              color="info"
              sx={{
                backgroundColor: "#007bff",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
                borderRadius: "4px",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              Save
            </Button>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default HeadersAndAuth;
