import {
  Typography,
  Box,
  IconButton,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { useState, useEffect } from "react";
import Editor from "@monaco-editor/react";
import { toast } from "react-toastify";

const ResponseAndMessage = ({
  messageLength,
  requestBody,
  setCarrierData,
  openModal,
}) => {
  const [parsedJSX, setParsedJSX] = useState(null);

  const handleEditorChange = (value) => {
    setCarrierData((prev) => ({
      ...prev,
      requestBody: {
        ...prev.requestBody,
        body: value, // Update the body in the parent state
      },
    }));
  };

  const handleRequestTypeChange = (event) => {
    setCarrierData((prev) => ({
      ...prev,
      requestBody: {
        ...prev.requestBody,
        type: event.target.value, // Update the type in the parent state
      },
    }));
  };

  // Transform object to include type and value
  const transformObject = (obj) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      if (typeof value === "object" && value !== null) {
        acc[key] = transformObject(value);
      } else {
        acc[key] = {
          type: "1",
          value: Array.isArray(value) ? [...value] : value,
        };
      }
      return acc;
    }, {});

  // Generate JSX while managing nested keys
  const generateJSX = (obj, parentKey = "") =>
    Object.entries(obj).map(([key, value]) => {
      console.log("Key Of Response:", obj);
      const fullKey = parentKey ? `${parentKey}.${key}` : key; // Construct full key for nested objects

      if (typeof value === "object" && value.type === "1") {
        return (
          <MDBox
            key={fullKey}
            padding={"8px"}
            marginBottom={"8px"}
            border={"1px solid #e0e0e0"}
            borderRadius={"8px"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#f0f8ff",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "#e6f7ff",
              },
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              {key}
            </Typography>
            {value.configured ? (
              <IconButton
                onClick={() => openModal(fullKey)}
                size="small"
                sx={{
                  color: "#007bff",
                  "&:hover": {
                    color: "#0056b3",
                  },
                }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Button
                onClick={() => openModal(fullKey)}
                variant="text"
                size="small"
                color="primary"
                // sx={{
                //   backgroundColor: "#007bff",
                //   color: "#fff",
                //   textTransform: "none",
                //   fontWeight: "bold",
                //   borderRadius: "4px",
                //   "&:hover": {
                //     backgroundColor: "#0056b3",
                //   },
                // }}
              >
                Edit
              </Button>
            )}
          </MDBox>
        );
      } else if (typeof value === "object") {
        return (
          <MDBox key={fullKey} marginBottom={"8px"} padding={"8px"}>
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              {key}:
            </Typography>
            <MDBox marginLeft={"16px"}>
              {generateJSX(value, fullKey)}{" "}
              {/* Recursive call for nested objects */}
            </MDBox>
          </MDBox>
        );
      }
      return null;
    });

  const parseAndPrepareJSX = () => {
    if (!requestBody.body.trim()) {
      toast.error("Please enter a valid body before configuring.");
      return;
    }

    try {
      const parsedBody = JSON.parse(requestBody.body); // Parse the JSON body
      const transformedObject = transformObject(parsedBody); // Transform the object
      const newRequestBody = JSON.stringify(transformedObject, null, 2); // Format JSON

      // Update the transformed object in the parent state
      setCarrierData((prev) => ({
        ...prev,
        requestBody: {
          ...prev.requestBody,
          body: newRequestBody,
        },
      }));

      const jsx = generateJSX(transformedObject);
      setParsedJSX(jsx);
    } catch (error) {
      toast.error("The body is not a valid JSON. Please correct it.");
      console.error("Error parsing JSON:", error);
    }
  };

  // useEffect(() => {
  //   if (requestBody.body.trim()) {
  //     const parsedBody = JSON.parse(requestBody.body);
  //     const jsx = generateJSX(parsedBody);
  //     setParsedJSX(jsx);
  //   }
  // }, [requestBody.body]);

  return (
    <MDBox margin={"16px 10px"}>
      <MDBox
        display="flex"
        alignItems="center"
        marginBottom={"12px"}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h6" fontWeight="500">
            Request Body Configuration
          </Typography>
          <Tooltip title="Define the request body structure and configure the fields as needed.">
            <IconButton size="small" sx={{ marginLeft: "1px" }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Message Character Length Input */}
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ whiteSpace: "nowrap" }}
        >
          <Typography
            sx={{
              marginRight: "8px",
              fontSize: "14px",
              fontWeight: "500",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Message Character Limit:
          </Typography>
          <MDInput
            type="number"
            label="Max Characters"
            value={messageLength || ""}
            onChange={(e) =>
              setCarrierData((prev) => ({
                ...prev,
                messageLength: e.target.value
                  ? parseInt(e.target.value, 10)
                  : "",
              }))
            }
            sx={{
              width: "50%",
              minWidth: "120px",
            }}
          />
        </MDBox>
      </MDBox>

      <MDBox display="flex" gap={"16px"}>
        {/* Request Body Section */}
        <MDBox
          flex={1}
          padding={"16px"}
          width={"100%"}
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          sx={{
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box display="flex" alignItems="center" marginBottom={"8px"}>
            <Typography variant="subtitle1" fontWeight={"500"}>
              Request Body
            </Typography>
            <Tooltip title="Please enter or paste a valid request body in the below editor and click on Configure Button">
              <IconButton size="small" sx={{ marginLeft: "8px" }}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          {parsedJSX ? (
            <>
              <MDBox sx={{ maxHeight: "300px", overflowY: "auto" }}>
                {parsedJSX}
              </MDBox>
              <Button
                onClick={() => {
                  setCarrierData((prev) => ({
                    ...prev,
                    requestBody: { body: "", type: "JSON" },
                  }));
                  setParsedJSX(null);
                }}
                variant="outlined"
                sx={{
                  marginTop: "12px",
                  borderColor: "#007bff",
                  color: "#007bff",
                  textTransform: "none",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#e6f7ff",
                  },
                }}
              >
                Re-enter JSON
              </Button>
            </>
          ) : (
            <>
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                  height: "150px",
                  overflow: "hidden",
                }}
              >
                <Editor
                  height="150px"
                  defaultLanguage="json"
                  value={requestBody.body}
                  onChange={handleEditorChange}
                  options={{
                    minimap: { enabled: false },
                    scrollBeyondLastLine: false,
                    wordWrap: "on",
                    lineNumbers: "off",
                  }}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop={"12px"}
              >
                <FormControl
                  size="small"
                  sx={{
                    minWidth: 150,
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                      height: "40px",
                    },
                  }}
                >
                  <InputLabel
                    id="request-type-label"
                    sx={{
                      // transform: "translate(14px, -10px) scale(1)",
                      // fontSize: "1rem",
                      background: "white",
                      px: 0.3,
                    }}
                  >
                    Request Type
                  </InputLabel>
                  <Select
                    labelId="request-type-label"
                    value={requestBody.type}
                    onChange={handleRequestTypeChange}
                    label="Request Type"
                    sx={{
                      height: "50px",
                      ".MuiSelect-outlined": {
                        display: "flex",
                        alignItems: "center",
                      },
                    }}
                  >
                    <MenuItem value="JSON">JSON</MenuItem>
                    <MenuItem value="Form Data">Form Data</MenuItem>
                    <MenuItem value="x-www-form-urlencoded">
                      x-www-form-urlencoded
                    </MenuItem>
                  </Select>
                </FormControl>
                <Button
                  onClick={parseAndPrepareJSX}
                  variant="contained"
                  size="medium"
                  sx={{
                    backgroundColor: "#007bff", // Optional custom background
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: "bold",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "#0056b3", // Optional hover state
                    },
                  }}
                >
                  Configure
                </Button>
              </Box>
            </>
          )}
        </MDBox>

        {/* Response Body Section */}
        {/* <MDBox
          flex={1}
          padding={"16px"}
          border={"1px solid #ccc"}
          borderRadius={"8px"}
          sx={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="subtitle1"
            fontWeight={"500"}
            marginBottom={"8px"}
          >
            Response Body
          </Typography>
          {responseBody ? (
            <Typography>{JSON.stringify(responseBody, null, 2)}</Typography>
          ) : (
            <Typography color="textSecondary" fontSize={"14px"}>
              No response body defined
            </Typography>
          )}
        </MDBox> */}
      </MDBox>
    </MDBox>
  );
};

export default ResponseAndMessage;
