import { Close, Edit } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AppConstants from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APP_COLOR } from "constants/Color";

const BroadcastMeta = ({ groupData, onUpdateInput }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currGroupData, setCurrGroupData] = useState();
  const categories = useSelector((state) => state.app.dlc_categories);

  const handleNameChange = (e) => {
    if (!isEditing) {
      setIsEditing(true);
    }
    setCurrGroupData((prev) => {
      return {
        ...prev,
        name: e.target.value,
      };
    });
  };
  useEffect(() => {
    if (groupData) {
      setCurrGroupData(groupData);
    }
  }, [groupData]);

  return (
    <MDBox display="flex" width="100%" flexDirection="column">
      <MDBox
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <MDBox display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h6" sx={{ width: "110px" }} fontWeight="medium">
            Group name : &nbsp;
          </Typography>
          <MDInput
            value={currGroupData ? currGroupData?.name : ""}
            onChange={handleNameChange}
            sx={{ width: "345px" }}
            fullWidth
            type="text"
          />
        </MDBox>
        <MDBox
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          sx={{ marginTop: "12px", width: "100%" }}
        >
          <Typography
            sx={{ height: "48px", width: "110px", paddingTop: "8px" }}
            variant="h6"
            fontWeight="medium"
          >
            Category : &nbsp;
          </Typography>
          <FormControl sx={{ width: "345px" }}>
            {currGroupData && (
              <Select
                value={currGroupData?.category?.id}
                id="dlc-select"
                sx={{
                  height: "42px",
                    "& .MuiSvgIcon-root": {
                        color: `${APP_COLOR.APP_SECONDARY_COLOR}`,
                        display: "inline-block",
                        fontSize:'22px!important'
                    }
                }}
                onChange={(e) => {
                  if (!isEditing) {
                    setIsEditing(true);
                  }
                  setCurrGroupData((prev) => {
                    return {
                      ...prev,
                      category: {
                        id: e.target.value,
                      },
                    };
                  });
                }}
              >
                {categories?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Typography color={"info"} margin={"8px"} fontSize={"12px"}>
              {AppConstants.DLC_COMPLIANCE_MESSAGE_EDIT}
            </Typography>
          </FormControl>
        </MDBox>
        {isEditing && (
          <MDBox
            style={{ marginTop: "12px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              onClick={() => {
                setIsEditing(false);
                setCurrGroupData(groupData);
              }}
              sx={{ marginRight: "12px" }}
              size={"medium"}
              color="info"
            >
              Cancel
            </Button>
            <MDButton
              onClick={() => {
                setIsEditing(false);
                setCurrGroupData(groupData);
                onUpdateInput(currGroupData);
              }}
              size={"medium"}
              color="info"
            >
              Update
            </MDButton>
          </MDBox>
        )}
      </MDBox>
    </MDBox>
  );
};
export default memo(BroadcastMeta);
