import { Box, Typography } from "@mui/material";

const UnreadDivider = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      width: "100%",
      margin: "10px 0",
    }}
  >
    {/* Left Line */}
    <Box
      sx={{
        flex: 1,
        height: "1px",
        backgroundColor: "#1976d2", // Default MUI blue color
      }}
    />
    {/* Center Text */}
    <Typography
      sx={{
        margin: "0 10px",
        fontSize: "14px",
        color: "#1976d2", // Default MUI blue color
        fontWeight: "bold", // Optional for better visibility
        whiteSpace: "nowrap",
      }}
    >
      Unread Messages
    </Typography>
    {/* Right Line */}
    <Box
      sx={{
        flex: 1,
        height: "1px",
        backgroundColor: "#1976d2", // Default MUI blue color
      }}
    />
  </Box>
);

export default UnreadDivider;
