import { BASE_URL } from "constants/Endpoints";
import fetch from "core/fetch";

const CARRIER_APPEND = "/phone/carrier";

export const saveCarrier = async ({ req, token }) => {
  const response = await fetch(`${BASE_URL}${CARRIER_APPEND}`, {
    method: "POST",
    body: JSON.stringify(req),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not call the endpoint.");
  }
  return data;
};

export const getAllCarrier = async (token) => {
  const response = await fetch(`${BASE_URL}${CARRIER_APPEND}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message || "Could not call the endpoint.");
  }
  return data;
};
