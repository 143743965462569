import {
  Typography,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MDBox from "lib/components/MDBox";
import MDButton from "lib/components/MDButton";
import MDInput from "lib/components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import AppModal from "ui/AppModal";
import ParamsAndQuery from "./Sections/ParamsAndQuery";
import HeadersAndAuth from "./Sections/HeadersAndAuth";
import ResponseAndMessage from "./Sections/ResponseAndMessage";
import { MESSAGE_SCHEMA } from "constants/AppConstants";

const CarrierForm = ({ carrierData, setCarrierData, onSubmit }) => {
  const [modalData, setModalData] = useState(null); // Data for currently configured key
  const [objectPaths, setObjectPaths] = useState([]);
  const [urlConfigured, setUrlConfigured] = useState(false);

  const extractObjectPaths = (obj, prefix = "") => {
    let paths = [];
    for (const key in obj) {
      const fullPath = prefix ? `${prefix}.${key}` : key;
      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        paths = paths.concat(extractObjectPaths(obj[key], fullPath));
      } else {
        paths.push(fullPath);
      }
    }

    console.log("Path Objects", paths);
    return paths;
  };

  const extractParamsAndQueries = (url) => {
    const urlObject = new URL(url);

    const queries = Array.from(urlObject.searchParams.entries()).map(
      ([key, value]) => ({
        key,
        type: "1", // Default type
        value: value || null,
      })
    );

    const pathSegments = urlObject.pathname
      .split("/")
      .filter((segment) => segment.startsWith(":"));

    const params = pathSegments.map((segment) => ({
      key: segment.replace(":", ""), // Remove the ':' prefix
      type: "1", // Default type
      value: null, // Default value
    }));

    return { params, queries };
  };

  const onCarrierNameChange = (e) => {
    setCarrierData((prev) => ({
      ...prev,
      carrier_name: e.target.value,
    }));
  };

  const onUrlChange = (e) => {
    const urlValue = e.target?.value?.trim();
    setCarrierData((prev) => ({
      ...prev,
      url: urlValue,
    }));
  };

  const handleConfigureClick = () => {
    const urlValue = carrierData.url?.trim();

    const validUrl = urlValue.match(/^(http:\/\/|https:\/\/)/)
      ? urlValue
      : `https://${urlValue}`;

    try {
      new URL(validUrl);
      const { params, queries } = extractParamsAndQueries(validUrl);

      setCarrierData((prev) => ({
        ...prev,
        url: validUrl,
        params,
        queries,
      }));

      setUrlConfigured(true);
    } catch (error) {
      toast.error("Invalid URL. Please enter a valid URL.");
    }
  };

  const handleReEnterClick = () => {
    setCarrierData((prev) => ({
      ...prev,
      params: [], // Clear existing params
      queries: [], // Clear existing queries
    }));

    setUrlConfigured(false); // Toggle back to Configure
  };

  const onAddendumChange = (e) => {
    setCarrierData((prev) => ({
      ...prev,
      addendumRequired: e.target.checked,
    }));
  };

  const openModal = (section, key) => {
    setCarrierData((prev) => {
      console.log("Section Data Key", section, key);
      function getNestedProperty(obj, path) {
        return path.split(".").reduce((acc, key) => acc && acc[key], obj);
      }

      let sectionData;

      if (section === "headers" && key === null) {
        // Add a new header
        sectionData = {
          key: "",
          type: "1",
          value: "",
          configured: false,
          needsPhone: false,
        };
      } else if (section !== "requestBody") {
        // Retrieve the existing data for params, queries, or headers
        sectionData = prev[section]?.find((item) => item.key === key);
      } else if (section === "requestBody") {
        const requestBody =
          typeof prev.requestBody.body === "string"
            ? JSON.parse(prev.requestBody.body)
            : prev.requestBody.body;

        sectionData = getNestedProperty(requestBody, key);
      }

      console.log("Section Data Final", sectionData, key);

      // Set modal data
      setModalData({
        section,
        key,
        ...sectionData,
        configured: sectionData?.configured || false,
        needsPhone: sectionData?.needsPhone || false,
      });

      return prev; // No state mutation
    });
  };

  const closeModal = (updatedData) => {
    if (!updatedData) {
      setModalData(null); // Close modal without changes
      return;
    }

    const { section, key, type, value, needsPhone } = updatedData;

    console.log("Updated Data", updatedData);

    if (!key || !section || !type) {
      toast.error("Invalid data for update.");
      return;
    }

    function updateNestedProperty(obj, path, newData) {
      const keys = path.split(".");
      const lastKey = keys.pop();
      let target = obj;

      keys.forEach((key) => {
        if (!target[key]) target[key] = {}; // Ensure the nested object exists
        target = target[key];
      });

      target[lastKey] = newData;
      return obj;
    }

    setCarrierData((prev) => {
      const updatedCarrierData = { ...prev };

      if (section === "headers") {
        const existingHeaderIndex = updatedCarrierData.headers.findIndex(
          (header) => header.key === key
        );

        if (existingHeaderIndex !== -1) {
          // Update the existing header
          updatedCarrierData.headers[existingHeaderIndex] = {
            key,
            type,
            value,
            configured: true,
            needsPhone,
          };
        } else {
          // Add a new header
          updatedCarrierData.headers.push({
            key,
            type,
            value,
            configured: true,
            needsPhone,
          });
        }
      } else if (section === "params" || section === "queries") {
        const targetArray =
          section === "params"
            ? updatedCarrierData.params
            : updatedCarrierData.queries;

        const existingIndex = targetArray.findIndex((item) => item.key === key);

        if (existingIndex !== -1) {
          // Update the existing parameter or query
          targetArray[existingIndex] = {
            key,
            type,
            value,
            configured: true,
            needsPhone,
          };
        } else {
          // Add a new parameter or query
          targetArray.push({ key, type, value, configured: true, needsPhone });
        }
      } else if (section === "requestBody") {
        const requestBody =
          typeof prev.requestBody.body === "string"
            ? JSON.parse(prev.requestBody.body)
            : prev.requestBody.body;

        const updatedBody = updateNestedProperty(requestBody, key, {
          type,
          value,
          configured: true,
          needsPhone,
        });

        console.log("Updated Body", updatedBody);

        return {
          ...prev,
          requestBody: {
            ...prev.requestBody,
            body: JSON.stringify(updatedBody),
          },
        };
      }

      return updatedCarrierData;
    });

    toast.success("Key updated successfully!");
    setModalData(null);
  };

  // const onSubmit = () => {
  //   console.log("Submitting carrier data:", carrierData);
  //   onSubmit(carrierData);
  // };

  useEffect(() => {
    const paths = extractObjectPaths(MESSAGE_SCHEMA);
    setObjectPaths(paths); // Save the paths for dropdown options
  }, []);

  useEffect(() => {
    console.log("Modal Data Carrier", carrierData, modalData);
  }, [carrierData, modalData]);

  return (
    <MDBox margin={"12px"} display="flex" flexDirection="column" width="100%">
      <MDBox margin={"8px"}>
        <MDInput
          fullWidth
          onChange={onCarrierNameChange}
          type="text"
          label="Carrier Name"
          value={carrierData.carrier_name}
        />
        <Typography margin={"8px"} fontSize={"12px"}>
          A unique name for the carrier
        </Typography>
      </MDBox>

      <MDBox margin={"8px"} display="flex" alignItems="center">
        <MDInput
          sx={{ flex: 1, marginRight: "4px" }}
          type="text"
          label="Enter URL"
          value={carrierData.url}
          onChange={onUrlChange}
          disabled={urlConfigured}
        />
        <MDButton
          onClick={urlConfigured ? handleReEnterClick : handleConfigureClick}
          variant="contained"
          color="info"
          sx={{
            marginLeft: "8px",
            backgroundColor: "#007bff",
            color: "#fff",
            textTransform: "none",
            fontWeight: "bold",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: "#0056b3",
            },
          }}
        >
          {urlConfigured ? "Re-enter URL" : "Configure"}
        </MDButton>
      </MDBox>

      <MDBox
        sx={{ marginLeft: "10px", marginTop: "6px", marginBottom: "8px" }}
        display="flex"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={carrierData.addendumRequired || false}
              onChange={onAddendumChange}
            />
          }
          label="Addendum Required"
        />
        <Tooltip title="Check this box if an addendum is required for the carrier">
          <IconButton size="small" sx={{ marginLeft: "4px" }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </MDBox>

      {/* Limit Input Box */}
      <MDBox margin={"8px"} display="flex" alignItems="center">
        <MDInput
          type="number"
          label="Message Limit"
          value={carrierData.limit || ""}
          onChange={(e) =>
            setCarrierData((prev) => ({
              ...prev,
              limit: e.target.value ? parseInt(e.target.value, 10) : "",
            }))
          }
          sx={{
            width: "15%",
          }}
        />
        <Tooltip title="Enter Message Per Minute Limit for the carrier">
          <IconButton size="small" sx={{ marginLeft: "8px" }}>
            <InfoIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </MDBox>

      <ParamsAndQuery
        params={carrierData.params}
        queries={carrierData.queries}
        openModal={openModal}
      />

      <HeadersAndAuth
        headers={carrierData.headers}
        auth={carrierData.auth}
        setCarrierData={setCarrierData}
        openModal={(key) => openModal("headers", key)}
      />

      <ResponseAndMessage
        messageLength={carrierData.messageLength}
        requestBody={carrierData.requestBody}
        setCarrierData={setCarrierData}
        openModal={(key) => openModal("requestBody", key)}
      />

      <MDBox margin={"12px"} display="flex" justifyContent="flex-end">
        <MDButton onClick={onSubmit} variant="gradient" color="info">
          Save
        </MDButton>
      </MDBox>

      {modalData && (
        <AppModal
          heading={`Configure ${modalData.key || "Key"}`}
          onModalClose={(updatedData) => closeModal(updatedData)}
          mediaModalClose={true}
        >
          <MDBox mt="20px">
            {/* Key Input for Headers */}
            {modalData.section === "headers" && (
              <>
                <MDInput
                  label="Header Name"
                  fullWidth
                  value={modalData.key || ""}
                  onChange={(e) =>
                    setModalData((prev) => ({ ...prev, key: e.target.value }))
                  }
                  sx={{ mb: 4 }}
                />
              </>
            )}

            {/* Type Selection */}
            <FormControl fullWidth sx={{ mb: 2, position: "relative" }}>
              <InputLabel
                id="type-select-label"
                sx={{
                  background: "white",
                  px: 0.3,
                }}
              >
                Type
              </InputLabel>
              <Select
                labelId="type-select-label"
                value={modalData.type || ""}
                onChange={(e) => {
                  const newType = e.target.value;
                  setModalData((prev) => ({
                    ...prev,
                    type: newType,
                    value: newType === "3" ? "" : prev.value, // Reset value if Type 3 is selected
                  }));
                }}
                label="Type"
                sx={{
                  height: "50px",
                  "& .MuiSelect-select": {
                    fontSize: "0.875rem",
                    padding: "10px 14px",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="medium"
                      sx={{
                        padding: "0",
                        marginLeft: "15px",
                        color: "#007bff",
                      }}
                    >
                      <ArrowDropDownIcon
                        fontSize="inherit"
                        sx={{ fontSize: "1.5rem" }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              >
                <MenuItem value="1">Type 1</MenuItem>
                <MenuItem value="2">Type 2</MenuItem>
                <MenuItem value="3">Type 3</MenuItem>
              </Select>
              {/* Info Button with Instructions */}
              <Tooltip
                title={
                  <div>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                      Type Selection Guide:
                    </Typography>
                    <Typography variant="body2" sx={{ lineHeight: "1.5" }}>
                      <strong>Type 1:</strong> Use for static values such as
                      strings or numbers.
                      <br />
                      <strong>Type 2:</strong> Choose this type for dynamic keys
                      and conditional values (e.g., key-based true/false
                      mappings).
                      <br />
                      <strong>Type 3:</strong> Select for mapping keys with keys
                      of current message schema.
                    </Typography>
                  </div>
                }
                arrow
              >
                <IconButton
                  size="small"
                  sx={{
                    position: "absolute",
                    top: "10px", // Adjust vertical alignment
                    right: "-32px", // Adjust horizontal alignment
                  }}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </FormControl>

            {modalData.type === "2" && (
              <MDBox>
                {/* Key Selection */}
                <Autocomplete
                  options={objectPaths}
                  getOptionLabel={(option) => option}
                  value={modalData.value?.key || ""}
                  onChange={(event, newValue) => {
                    setModalData((prev) => ({
                      ...prev,
                      value: {
                        ...prev.value,
                        key: newValue || "",
                      },
                    }));
                  }}
                  renderInput={(params) => (
                    <MDInput {...params} label="Select Key" fullWidth />
                  )}
                  sx={{ mb: 2 }}
                />

                {/* Condition Selection */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel
                    id="condition-select-label"
                    sx={{
                      background: "white",
                      px: 0.3,
                    }}
                  >
                    Condition
                  </InputLabel>
                  <Select
                    labelId="condition-select-label"
                    value={modalData.value?.condition || ""}
                    onChange={(e) => {
                      setModalData((prev) => ({
                        ...prev,
                        value: {
                          ...prev.value,
                          condition: e.target.value,
                        },
                      }));
                    }}
                    sx={{
                      height: "50px",
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="medium"
                          sx={{
                            padding: "0",
                            marginLeft: "15px",
                            // color: "#007bff",
                          }}
                        >
                          <ArrowDropDownIcon
                            fontSize="inherit"
                            sx={{ fontSize: "1.5rem" }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                    <MenuItem value="IS_EMPTY">IS_EMPTY</MenuItem>
                  </Select>
                </FormControl>

                {/* True and False Values */}
                <MDInput
                  label="True Condition Value"
                  fullWidth
                  value={modalData.value?.trueValue || ""}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      value: {
                        ...prev.value,
                        trueValue: e.target.value,
                      },
                    }))
                  }
                  sx={{ mb: 2 }}
                />
                <MDInput
                  label="False Condition Value"
                  fullWidth
                  value={modalData.value?.falseValue || ""}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      value: {
                        ...prev.value,
                        falseValue: e.target.value,
                      },
                    }))
                  }
                  sx={{ mb: 2 }}
                />
              </MDBox>
            )}

            {/* Autocomplete for Type 3 */}
            {modalData.type === "3" && (
              <Autocomplete
                options={objectPaths}
                getOptionLabel={(option) => option}
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    option.toLowerCase().includes(inputValue.toLowerCase())
                  )
                }
                value={modalData.value || ""}
                onChange={(event, newValue) => {
                  setModalData((prev) => ({
                    ...prev,
                    value: newValue || "",
                  }));
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    label="Select Object Notation"
                    fullWidth
                  />
                )}
                sx={{ mb: 2 }}
              />
            )}

            {/* Value Input for Other Types */}
            {modalData.type === "1" && (
              <>
                {/* <Typography
                  variant="subtitle1"
                  fontWeight="400"
                  fontSize={"1rem"}
                  sx={{ mb: 1 }}
                >
                  Value for Type {modalData.type || "N/A"}
                </Typography> */}
                <MDInput
                  label={`Enter Value`}
                  fullWidth
                  value={modalData.value || ""}
                  onChange={(e) =>
                    setModalData((prev) => ({ ...prev, value: e.target.value }))
                  }
                  sx={{
                    mb: 2,
                  }}
                />
              </>
            )}

            {/* Needs Phone Checkbox */}
            <MDBox mt={2} display="flex" alignItems="center" gap={1}>
              <Typography
                variant="subtitle2"
                fontWeight="400"
                fontSize={"1rem"}
              >
                Depends on Phone Number:
              </Typography>
              <input
                type="checkbox"
                checked={modalData?.needsPhone || false}
                onChange={(e) => {
                  setModalData((prev) => ({
                    ...prev,
                    needsPhone: e.target.checked, // Update needsPhone at the root level
                  }));
                }}
              />
              <Tooltip title="Check if this key requires phone number information.">
                <IconButton size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </MDBox>

            {/* Save and Delete Buttons */}
            <MDBox
              mt={2}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <MDButton
                onClick={() => closeModal(modalData)}
                variant="contained"
                color="info"
                sx={{
                  backgroundColor: "#007bff",
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: "bold",
                  borderRadius: "4px",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                }}
              >
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </AppModal>
      )}
    </MDBox>
  );
};

export default CarrierForm;
